import PropTypes from "prop-types"
import React from 'react'
import { Card } from 'react-bootstrap'

const ExportCard = (props) => { 
  return (
    <Card className='card__export d-flex justify-content-center align-items-center' onClick={props.onClick} >
      {props.children}
      <Card.Title>
        {props.title}
      </Card.Title>
    </Card>
  )
 }

ExportCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func
}

 export default ExportCard
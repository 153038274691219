import { useState, useEffect, createContext, useMemo } from "react";
// endpoints
import { getAllClients } from "../api/pathsForHook/clients";
//methods called
import { methodGet } from "../api/methods";

export const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClients = async () => {
      const rs = await methodGet(getAllClients(), { is_demo: false });
      if (rs.status === 200) {
        setClients(rs.data);
      }
    };
    getClients();
  }, []);

  const values = useMemo(() => ({ clients }), [clients]);

  return (
    <GeneralContext.Provider value={values}>{children}</GeneralContext.Provider>
  );
};

export default GeneralProvider;

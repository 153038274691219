import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { HiOutlineBell } from 'react-icons/hi'

import Button from '../../../components/buttons/CustomButton'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import CustomSelect from '../../../components/select'
import Input from '../../../components/input'

import { GeneralContext } from '../../../contexts/GeneralContext'

import useFetch from '../../../hooks/useFetch'
import { getExportProductMarc21 } from '../../../api/pathsForHook/products'

import { downloadFile, showToast } from '../../../helpers'

const ExportLaons = () => {
  const [enabled, setEnabled] = useState(true)
  const [codeError, setCodeError] = useState(null)

  const { methodGet } = useFetch()
  const { clients } = useContext(GeneralContext)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation(['exports', 'forms'])

  useEffect(() => {
    if (codeError === 403) showToast('error', t('export.clientForbidden'))
  }, [codeError])

  const onSubmit = async (data) => {
    let dataTemp = data
    dataTemp.uuid = 'b873527e-eda0-457d-a67f-23dd8b7cf050'
    if (!data.date) {
      dataTemp['all'] = true
      await methodGet(getExportProductMarc21(), dataTemp)
      showToast('success', t('export.successful') + ' ' + t('export.receiveEmail'))
    } else {
      downloadFile(
        getExportProductMarc21(),
        dataTemp,
        localStorage.getItem('tokenAuth'),
        `marc21_${data.client}`,
        setEnabled,
        setCodeError,
        'xml',
      )
    }
    reset({
      client: '',
      email: '',
      date: '',
    })
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('export.marcxml')}</h2>
      <Row>
        <Col>
          <Alert variant="info d-flex flex-direction-row">
            <HiOutlineBell fontSize={40} />
            <div className="ms-3">
              Seleccione un cliente e ingrese un correo electrónico; las fechas son opcionales. <br />
              <ul>
                <li>Si seleccionas una fecha: el archivo se descargara, puede tardar un par de minutos.</li>
                <li>
                  Si no seleccionas una fecha: Cuando se termine de generar el XML con los registros te enviaremos un
                  correo electrónico con un enlace para que puedas descargar el XML.
                </li>
              </ul>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Controller
            name="client"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('forms.requiredField', { ns: 'forms' }),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                label="Cliente*"
                options={clients.map((c) => ({ id: c.name, name: c.name }))}
                value={value}
                handleSelect={onChange}
                error={errors.client?.message}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('forms.requiredField', { ns: 'forms' }),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('forms.invalidEmail', { ns: 'forms' }),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('forms.email', { ns: 'forms' }) + '*'}
                placeholder={t('forms.email', { ns: 'forms' })}
                type="email"
                value={value}
                onChange={onChange}
                error={errors.email?.message}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input label="Fecha" placeholder="Fecha de altas" type="date" value={value} onChange={onChange} />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-4 d-flex flex-row-reverse">
          <Button disabled={!enabled} onClick={handleSubmit(onSubmit)}>
            {t('export.export', { ns: 'exports' })}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ExportLaons

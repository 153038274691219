// ========== GET METHODS ==========
export const getGeneralInfo = () => 'statistics/administrator/general-information/'

export const getClients = () => 'statistics/administrator/clients/'

export const getAdminStatistics = () => 'reports/loan-history-admin/general-statistics/'

export const getAdminStatisticsFilters = () => 'reports/loan-history-admin/filters/'

// exports
export const getAdminExportLoans30Days = () => 'reports/loan-history-admin/loans-30-last-days/'

export const getAdminExportAmount30Days = () => 'reports/loan-history-admin/amount-30-last-days/'

export const exportKidsEdyeApi = () => 'reports/export-edye-excel'

export const exportLifeAndHealthEdyeApi = () => 'reports/export-vida-y-salud-excel'

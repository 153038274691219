import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'

import ChartContainer from './ChartContainer'

const BarChartCustom = ({ data, labels, label, title, colors, loading, ...props }) => {
  const COLOR_GENERATOR = {
    generate: (length) => Array.from({ length }, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`),
  }

  const generateDatasets = (data) => {
    const result = []
    data?.map((singleData, index) => {
      result.push({
        label: loading ? 'Loading' : labels[index] ? labels[index] : label,
        data: loading ? [Math.floor(Math.random() * 91) + 10] : [singleData],
        backgroundColor: loading ? ['#00000022'] : colors || COLOR_GENERATOR.generate(1),
      })
    })
    return result
  }
  const barChartData = {
    labels: loading ? ['Loading'] : [' '],
    datasets: generateDatasets(data),
  }

  const options = () => ({
    elements: {
      bar: {
        borderSkipped: false,
        borderRadius: 10,
      },
    },
    plugins: {
      legend: {
        display: loading ? false : true,
        position: 'top',
        labels: {
          font: { size: 2 },
        },
      },
      tooltip: {
        enabled: loading ? false : true,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  })

  return (
    <ChartContainer title={title} className="bar__chart">
      <Bar options={options} data={barChartData} {...props} />
    </ChartContainer>
  )
}

export default BarChartCustom

import React, { useState, useEffect, useContext } from 'react'
// apis
import {
  assignClientSliderGroup,
  removeClientSliderGroup,
  getSliderGroup,
  getClientSliderGroup,
  changeClientSliderGroup,
  deleteSliderGroupApi,
} from '../../../api/pathsForHook/sliders'
import { getClientListGroup, editOrDeleteList } from '../../../api/pathsForHook/products'
import { getClients, getAllClients } from '../../../api/pathsForHook/clients'
// components
import { Container, Row, Col } from 'react-bootstrap'
import Table from '../../../components/table'
import SelectModal from '../../../components/modals/SelectModal'
import Loading from '../../../components/Loading'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb'
//translations
import { useTranslation } from 'react-i18next'
//CSS
import '../../../assets/css/group_lists.scss'
//icons
import { GoPencil } from 'react-icons/go'
import { RiDeleteBinLine } from 'react-icons/ri'
// helpers
import { showToast } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'
// routing
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
// context
import { AdminContext } from '../../../contexts/AdminContext'

const SlidersGroupsPage = () => {
  const { t, i18n } = useTranslation('clientsGroups', 'groupList')

  const [clients, setClients] = useState(null)
  const [groups, setGroups] = useState(null)
  const [clientsWithGroups, setClientsWithGroups] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedClient, setSelectClient] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()

  const [links, setLinks] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const { adminState, setAdminState } = useContext(AdminContext)
  const { methodGet, methodPost, methodDelete, methodPatch } = useFetch()

  const noneOption = { id: 0, name: t('chooseOption') }

  useEffect(() => {
    getClientsList(page)
    getCreatedGroups()
    getClientsWithGroups()
  }, [location])

  const handlePageChange = (e) => {
    setPage(e)
    getClientsList(e)
  }

  const getClientsList = async (currentPage) => {
    const payload = { page: currentPage, is_demo: false }
    try {
      const response = await methodGet(getClients(), payload)
      setClients(response.data.results)
      setTotalPages(response.data.total_pages)
      setLinks(response.data.links)
    } catch (error) {
      setClients('null')
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  const getCreatedGroups = async () => {
    const response = await methodGet(getSliderGroup())
    if (response) setGroups([noneOption, ...response.data.results])
    else {
      setGroups(null)
    }
  }

  const getClientsWithGroups = async () => {
    const url = getSliderGroup()
    const response = await methodGet(url, { page_size: 40 })
    if (response) {
      setClientsWithGroups(response.data.results)
    } else {
      setClientsWithGroups('null')
    }
  }

  const handleSelectedClient = (client) => {
    setSelectClient(client)
    setOpenModal(true)
  }

  const receiveClientsGroup = (data) => {
    if (clientsWithGroups === 'null') {
      showToast('error', t('error', { ns: 'common' }))
      return
    }
    const payloadSlider = { client: selectedClient.id }

    if (data > 0) {
      saveGroupForClient(payloadSlider, data)
    } else {
      deleteGroupForClient(selectedClient.id, selectedClient.slider_group?.id)
    }
  }

  const saveGroupForClient = async (payload, id) => {
    try {
      let response
      if (selectedClient.slider_group) {
        await methodDelete(removeClientSliderGroup(id, selectedClient.id))
        response = await methodPost(assignClientSliderGroup(id, selectedClient.id), payload)
      } else {
        response = await methodPost(assignClientSliderGroup(id, selectedClient.id), payload)
      }
      if (response) {
        getClientsList(1) && getClientsWithGroups()
        showToast('success', t('groupActions.saveSuccess'))
      } else {
        showToast('error', t('groupActions.saveError'))
      }
    } catch (error) {
      showToast('error', t('groupActions.saveError'))
    }
  }

  const deleteGroupForClient = async (clientId, groupId) => {
    try {
      const response = await methodDelete(deleteSliderGroupApi(groupId, clientId))
      if (response) {
        getClientsList(1) && getClientsWithGroups()
        showToast('success', t('groupActions.deleteSuccess'))
      } else {
        showToast('error', t('groupActions.deleteError'))
      }
    } catch (error) {
      console.log('Error', error)
      showToast('error', t('groupActions.deleteError'))
    }
  }

  const deleteSelectedList = async (clientId) => {
    try {
      const selectedClientGroup = clientsWithGroups?.find((pairing) => pairing.clients.includes(clientId))
      const response = await methodDelete(deleteSliderGroupApi(selectedClientGroup?.id, clientId))
      if (response) {
        showToast('success', t('deletedSuccessful'))
        getClientsList(1) && getClientsWithGroups()
        //navigate(0)
      } else {
        showToast('error', 'Error on delete')
      }
    } catch (error) {
      console.log('Error', error)
      showToast('error', 'Error on delete')
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Breadcrumb
        prevPageName={t('groupsOfSliders', { ns: 'groupList' })}
        activeItemName={t('assignClients', { ns: 'groupList' })}
        route={'/administrator/group-sliders'}
      />
      <SelectModal
        open={openModal}
        setModal={setOpenModal}
        selectedDetail={selectedClient}
        options={groups}
        sendGroupChange={receiveClientsGroup}
        label={t('group')}
        sliderMode={true}
        type={'slider'}
      />
      <Row>
        {!clients ? (
          <Loading />
        ) : clients ? (
          <Col>
            <Table
              header={t('headerTableClientsGroup', { returnObjects: true })}
              page={page}
              changePage={handlePageChange}
              next={links.next ? true : false}
              previous={links.previous ? true : false}
              totalPages={totalPages}
            >
              {clients?.map((client, index) => (
                <tr key={index}>
                  <td>{client?.name}</td>
                  <td>{client?.slider_group && client?.slider_group.name}</td>
                  <td className="d-flex justify-content-center actions-row-buttons">
                    <span onClick={() => handleSelectedClient(client)} className="action-btn-edit">
                      <GoPencil className="icon icon-edit" />
                    </span>
                    <span onClick={() => deleteSelectedList(client?.id)} className="action-btn-delete">
                      <RiDeleteBinLine />
                    </span>
                  </td>
                </tr>
              ))}
            </Table>
          </Col>
        ) : (
          <p>No Info</p>
        )}
      </Row>
    </Container>
  )
}

export default SlidersGroupsPage

import { useContext } from 'react'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// icons
import { BsFillPersonLinesFill, BsListUl, BsBoxSeam } from 'react-icons/bs'
import { MdDashboard } from 'react-icons/md'
import { FiLogOut, FiPieChart } from 'react-icons/fi'
import { GoDesktopDownload } from 'react-icons/go'
import { BsFillHouseDoorFill } from 'react-icons/bs'
import { BiCarousel } from 'react-icons/bi'
// helpers
import { UserContext } from '../../contexts'
import { setValueStorage } from '../../helpers'
import { getValueStorage } from '../../helpers'
import { createLanguageOptions } from '../../utilities'
import { clientName } from '../../constants'
// styles
import '../../assets/css/layouts.scss'
// components
import Select from '../select'
//hooks
import useSwitchLanguages from '../../hooks/useSwitchLanguages/switchLanguageHook'

const AdminSideBar = () => {
  const userContext = useContext(UserContext)
  let navigate = useNavigate()
  const { t } = useTranslation(['header', 'groupList'])
  const [selectLanguage, setSelectLanguage] = useSwitchLanguages('')

  const logout = () => {
    setValueStorage('tokenAuth', '')
    userContext.setIsAuth(false)
    navigate('/')
  }

  return (
    <>
      <div className="sidebar sidebar-desktop p-3">
        <Link to="/administrator" className="h2">
          {clientName}
        </Link>{' '}
        <hr style={{ border: '2px solid black' }} />
        <div className="nav nav-pills flex-column mb-auto">
          <NavLink to="/administrator" end>
            <MdDashboard fontSize={27} /> DASHBOARD
          </NavLink>
          <NavLink to="group-lists">
            <BsListUl fontSize={27} />
            {t('listGroup', { ns: 'groupList' }).toUpperCase()}
          </NavLink>
          <NavLink to="group-sliders">
            <BiCarousel fontSize={27} />
            {t('sliderGroup', { ns: 'groupList' }).toUpperCase()}
          </NavLink>
          <NavLink to="exports">
            <GoDesktopDownload fontSize={27} /> {t('exports').toUpperCase()}
          </NavLink>
          <NavLink to="content-management">
            <BsBoxSeam fontSize={27} /> {t('contentManagement').toUpperCase()}
          </NavLink>
          <NavLink to="statistics">
            <FiPieChart fontSize={27} /> {t('statistics').toUpperCase()}
          </NavLink>
        </div>
        <div className="language-select">
          <Select
            key="select-language"
            options={createLanguageOptions()}
            handleSelect={setSelectLanguage}
            value={getValueStorage('selectLanguage')}
          />
        </div>
        <div className="color-text cursor-pointer" onClick={logout}>
          <FiLogOut fontSize={27} /> {t('logOut')}
        </div>
      </div>

      <div className="sidebar sidebar-mobile p-1">
        <ul className="nav nav-pills flex-column mb-auto">
          <li>
            <Link to="/administrator" className="h2">
              <BsFillHouseDoorFill fontSize={27} />
            </Link>{' '}
          </li>
          <hr style={{ border: '2px solid black' }} />
          <li>
            <NavLink to="/administrator" end>
              <MdDashboard fontSize={27} />
            </NavLink>
          </li>
          <li>
            <NavLink to="group-lists">
              <BsListUl fontSize={27} />
            </NavLink>
          </li>
          <li>
            <NavLink to="group-sliders">
              <BiCarousel fontSize={27} />
            </NavLink>
          </li>
          {/*           <li>
            <NavLink to="clients-groups">
              <BsFillPersonLinesFill fontSize={27} />
            </NavLink>
          </li> */}
          <li>
            <NavLink to="exports">
              <GoDesktopDownload fontSize={27} />
            </NavLink>
          </li>
          <li>
            <NavLink to="content-management">
              <BsBoxSeam fontSize={27} />
            </NavLink>
          </li>
          <li>
            <NavLink to="statistics">
              <FiPieChart fontSize={27} />
            </NavLink>
          </li>
          <li>
            <div className="language-select">
              <Select
                key="select-language"
                options={createLanguageOptions()}
                handleSelect={setSelectLanguage}
                value={getValueStorage('selectLanguage')}
              />
            </div>
          </li>
          <li>
            <div className="logout">
              <FiLogOut fontSize={27} color="white" className="text-decoration-none color-text" />
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default AdminSideBar

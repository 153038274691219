import PropTypes from 'prop-types'
import React from 'react'

import { Form } from 'react-bootstrap'

import '../../assets/css/styles.scss'

const SelectCustom = ({ options, label, placeholder, defaultOption, value, defaultValue, handleSelect, error }) => {
  const getValue = (item) => {
    if (item.value) return item.value
    else if (item.id) return item.id
    return ''
  }

  return (
    <Form role="form">
      <Form.Group>
        {label ? <Form.Label>{label}</Form.Label> : null}
        <Form.Control
          onChange={(e) => {
            e.preventDefault()
            handleSelect(e.target.value)
          }}
          as="select"
          defaultValue={defaultValue}
          value={value}
        >
          {defaultOption && (
            <option selected value={defaultOption.value ? defaultOption.value : defaultOption.id}>
              {defaultOption.name}
            </option>
          )}
          {placeholder && (
            <option value="" key={'placeholder'}>
              {placeholder}
            </option>
          )}
          {options.map((item, index) => (
            <option key={index} value={getValue(item) ? getValue(item) : ''}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        {error && error !== '' && (
          <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  )
}

SelectCustom.propTypes = {
  defaultOption: PropTypes.any,
  defaultValue: PropTypes.any,
  handleSelect: PropTypes.func,
  label: PropTypes.any,
  options: PropTypes.array,
  value: PropTypes.any,
  error: PropTypes.string,
}

export default SelectCustom

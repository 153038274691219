// ========== GET METHODS ==========
export const getLoansByMonths = () => "statistics/provider/loans-year";

export const getYears = () => "statistics/provider/get-years";

export const getMonths = () => "statistics/provider/get-months";

export const getClients = () => "statistics/provider/get-clients";

export const getLoans = () => "statistics/provider/";

export const getLoanLibraries = () => "statistics/provider/loan-libraries/";

export const getSummary = () => "statistics/provider/loan-summary/";

export const getContent = () => "statistics/provider/loans-content/";

// ========== *** *** ==========

export const loansExportExcel = (query) => ({
  url: "statistics/provider/loan-export/",
  query,
});

import { Container } from "react-bootstrap"
import { useLocation } from "react-router-dom"



const ErrorPage = () => {
    const location = useLocation()
    return (
        <Container>
            <h1>Error:</h1>
            <h3>{location?.state?.status}</h3>
        </Container>
    )
}

export default ErrorPage
import React, { useState, useEffect } from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { TiDownloadOutline } from "react-icons/ti";
import axios from "axios";
// api
import { getContent, getClients } from "../../api/pathsForHook/provider";
// own hooks
import useFetch from "../../hooks/useFetch";
// constans
import { API_EFILM } from "../../constants";
// own components
import Table from "../../components/table";
import Select from "../../components/select";
import { CustomButton } from "../../components/buttons";
//translations
import { useTranslation } from "react-i18next";
// styles
import "../../assets/css/styles.scss";

const Contenido = () => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [contents, setContents] = useState(null);
  const [clients, setclients] = useState([]);
  const [client, setClient] = useState(null);
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [links, setLinks] =  useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const { t, i18n } = useTranslation(["content"]);

  const { methodGet } = useFetch();

  const loadContent = async (page) => {
    let payload = { page : page };
    if (client !== "" && client !== null) {
      payload = { client: client };
    }
    const response = await methodGet(getContent(), payload);
    if (response) {
      const { data } = response;
      setContents(data.results);
      setCount(data.cont);
      setAmount(data.amount);
      setLinks(data.links)
      setTotalPages(data.total_pages)
    }
  };

  const handlePageChange = (e) => {
    setPage(e)
    loadContent(e)
  }

  useEffect(() => {
    const loadClients = async () => {
      const response = await methodGet(getClients());
      if (response) {
        const { data } = response;
        let temp = data.map((item) => ({ value: item, name: item }));
        temp = [{ value: "", name: t("selectAClient") }, ...temp];
        setclients(temp);
      }
    };
    loadClients();
  }, []);

  useEffect(() => {
    loadContent(page);
  }, [client]);

  const exportLoanContent = async () => {
    setDisabledBtn(true);
    axios({
      url:
        API_EFILM +
        `statistics/provider/export-loans-content/?client=${client}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "JWT " + localStorage.getItem("tokenAuth"),
        "Accept-Language": i18n.language,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `loans_${new Date().toString()}.xls`);
      document.body.appendChild(link);
      link.click();
      setDisabledBtn(false);
    });
  };

  return (
    <Container className="d-flex justify-content-center flex-column">
      <h2>{t("consumptionByContent")}</h2>
      <div>
        <h3>{t("totals")}</h3>
        <div className="row my-3">
          <div className="col badge-info">{count} Un.</div>
          <div className="col badge-info">
            {amount} {process.env.REACT_APP_COIN}
          </div>
        </div>
      </div>
      <Row className="my-2">
        <Col>
          <Select options={clients} handleSelect={setClient} />
        </Col>
        <Col>
          <CustomButton
            disabled={disabledBtn}
            className="online-cprimary"
            onClick={exportLoanContent}
          >
            {t("export", { ns: "home" })}
            {disabledBtn ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <TiDownloadOutline />
            )}
          </CustomButton>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table
            header={t('headerTableContent', { returnObjects: true })}
            page={page}
            changePage={handlePageChange}
            next={links.next ? true : false}
            previous={links.previous ? true : false}
            totalPages={totalPages}
          >
            {contents &&
              contents.map((item, index) => (
                <tr key={index}>
                  <td>{item[0]}</td>
                  <td>{item[2]}</td>
                  <td>
                    {item[1]} {process.env.REACT_APP_COIN}
                  </td>
                  <td>{item[4]}</td>
                </tr>
              ))}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Contenido;

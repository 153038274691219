import React from 'react'

import { Table, Pagination } from 'react-bootstrap'
import '../../assets/css/styles.scss'

const TableCustom = ({ header, changePage, previous, next, page, ...props }) => {
  return (
    <Table striped bordered hover responsive className="mt-3">
      <thead className="text-center">
        <tr>
          {header.map((item, i) => (
            <td key={i}>{item}</td>
          ))}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
      {changePage && (
        <tfoot>
          <tr>
            <td colSpan={header.length}>
              <Pagination className="d-flex justify-content-center">
                {previous && <Pagination.Prev onClick={() => changePage(page - 1)} />}
                <Pagination.Item active>{page}</Pagination.Item>
                {next && <Pagination.Next onClick={() => changePage(page + 1)} />}
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  )
}

export default TableCustom

import React, { useContext, Suspense } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
// helpers
import { getValueStorage } from '../helpers'
// own contexts
import { UserContext } from '../contexts'
// own layout
import DefaultLayout from '../layouts/DefaultLayout'
import SecondaryLayout from '../layouts/SecondaryLayout'
import AdminLayout from '../layouts/AdminLayout'
// own HOC
import RequireAuth from '../HOCs/RequireAuth'
// own pages
import HomeProvider from '../pages/provider/home'
import HomeAdministrator from '../pages/admin/home'
import ClientsGroups from '../pages/admin/groups/ClientsGroups'
import SlidersGroups from '../pages/admin/groups/SlidersGroups'
import ContenidoProvider from '../pages/provider/Contenido'
import ClientsProvider from '../pages/provider/Clients'
import Login from '../pages/login'
import GroupListsAdministrador from '../pages/admin/groups/GroupLists'
import GroupDetails from '../pages/admin/groups/GroupDetails'
import Pinna from '../pages/provider/Pinna'
import Page404 from '../pages/404'
import ErrorPage from '../pages/ErrorPage'
import ContentManagement from '../pages/admin/content-management'
import ProductsManagement from '../pages/admin/content-management/ProductsManagement'
import ListsManagement from '../pages/admin/content-management/ListsManagement'
import CollectionsManagement from '../pages/admin/content-management/CollectionsManagement'
import ListDetail from '../pages/admin/content-management/ListDetail'
import GroupSliders from '../pages/admin/groups/GroupSliders'
import SliderGroupDetail from '../pages/admin/groups/SliderGroupDetail'
import CreateSlider from '../pages/admin/groups/CreateSlider'
import ActivateAll from '../pages/admin/content-management/ActivateAll'

import Statistics from '../pages/admin/statistics'
import LoanStatisticsAdmin from '../pages/admin/statistics/loans'
import UsersStatisticsAdmin from '../pages/admin/statistics/users'
import GeneralStatisticsAdmin from '../pages/admin/statistics/general'

import ExportsAdmin from '../pages/admin/exports'
import ExportLoans from '../pages/admin/exports/ExportLoans'
import ExportEdye from '../pages/admin/exports/ExportEdye'
import ExportXMLMarc from '../pages/admin/exports/ExportXMLMarc'
import ExportReportMC from '../pages/admin/exports/ExportReportMC'
import SummaryMonthReport from '../pages/admin/exports/ExportSummaryMonth'

const MainRoute = () => {
  const { isAuth } = useContext(UserContext)
  const userInfo = JSON.parse(getValueStorage('userInfo'))

  const redirection = () => {
    const userType = userInfo?.profile === 3 ? '/provider' : userInfo?.profile === 4 ? '/administrator' : null
    return <Navigate to={isAuth && userType ? userType : '/login'} />
  }

  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={redirection()} />
          <Route path="/login" element={<SecondaryLayout />}>
            <Route index element={!isAuth ? <Login /> : <Navigate to={'/'} />} />
          </Route>

          <Route
            path="administrator/*"
            element={
              <RequireAuth userNumber={4}>
                <AdminLayout />
              </RequireAuth>
            }
          >
            <Route index element={<HomeAdministrator />} />
            <Route path="group-lists" element={<GroupListsAdministrador />} />
            <Route path="group-sliders" element={<GroupSliders />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="statistics/loans" element={<LoanStatisticsAdmin />} />
            <Route path="statistics/users" element={<UsersStatisticsAdmin />} />
            <Route path="statistics/general" element={<GeneralStatisticsAdmin />} />
            <Route path="group-details/:id" element={<GroupDetails />} />
            <Route path="slider-group-details/:id" element={<SliderGroupDetail />} />
            <Route path="slider-group-details/:id/new-slider" element={<CreateSlider />} />
            <Route path="clients-groups" element={<ClientsGroups />} />
            <Route path="sliders-groups" element={<SlidersGroups />} />
            <Route path="exports" element={<ExportsAdmin />} />
            <Route path="exports/loans" element={<ExportLoans />} />
            <Route path="exports/edye" element={<ExportEdye />} />
            <Route path="exports/xml-marc" element={<ExportXMLMarc />} />
            <Route path="exports/report-mc" element={<ExportReportMC />} />
            <Route path="exports/summary-month" element={<SummaryMonthReport />} />
            <Route path="content-management" element={<ContentManagement />} />
            <Route path="content-management/products-management" element={<ProductsManagement />} />
            <Route path="content-management/collections" element={<CollectionsManagement />} />
            <Route path="content-management/list/:id" element={<ListDetail />} />
            <Route path="content-management/list/new-list" element={<ListDetail />} />
            <Route path="content-management/lists" element={<ListsManagement />} />
            <Route path="content-management/activate-all" element={<ActivateAll />} />
          </Route>

          <Route path="provider/*" element={<DefaultLayout />}>
            {userInfo?.profile === 3 &&
              (userInfo.providers && userInfo.providers[0] === 'pinna' ? (
                <Route index element={isAuth ? <Pinna /> : <Navigate to={'/login'} />} />
              ) : (
                <Route index element={isAuth ? <HomeProvider /> : <Navigate to={'/login'} />} />
              ))}
            <Route
              path="content"
              element={
                <RequireAuth userNumber={3}>
                  <ContenidoProvider />
                </RequireAuth>
              }
            />
            <Route
              path="clients"
              element={
                <RequireAuth userNumber={3}>
                  <ClientsProvider />
                </RequireAuth>
              }
            />
          </Route>

          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default MainRoute

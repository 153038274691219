import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ImTicket, ImPieChart, ImUser } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'
// components
import ExportCard from '../../../components/cards/ExportCard'

const StatisticsPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('statistics')
  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t('statistics', { ns: 'header' })}</h2>
      <Row>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('generalStatistics')} onClick={() => navigate('/administrator/statistics/general')}>
            <ImPieChart fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('loanStatistics')} onClick={() => navigate('/administrator/statistics/loans')}>
            <ImTicket fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('userStatistics')} onClick={() => navigate('/administrator/statistics/users')}>
            <ImUser fontSize={50} />
          </ExportCard>
        </Col>
      </Row>
    </Container>
  )
}

export default StatisticsPage

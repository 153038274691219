import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

// contexts
import { GeneralContext } from '../../../contexts/GeneralContext'
// components
import SelectCustom from '../../../components/select'
import { CustomButton } from '../../../components/buttons'
import ButtonGoBack from '../../../components/buttons/GoBackButton'

import useFetch from '../../../hooks/useFetch'
import { exportSummaryMonthReport } from '../../../api/pathsForHook/loans'

import { showToast } from '../../../helpers'
import { MONTHS } from '../../../constants'

const SummaryMonthReport = () => {
  const [years, setYears] = useState([])

  const { clients } = useContext(GeneralContext)
  const { methodExportExcel, loading, error } = useFetch()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation(['exports', 'forms', 'common'])

  useEffect(() => {
    const tempYears = []
    for (let i = 2016; i <= new Date().getFullYear(); i++) {
      tempYears.push(i)
    }
    setYears(tempYears)
  }, [])

  const onSubmit = async (data) => {
    await methodExportExcel(exportSummaryMonthReport(), 'summary_report', data)
    if (error) {
      showToast('error', t('error', { ns: 'common' }))
    } else {
      showToast('success', 'Action completed successfully')
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('export.summaryMonth')}</h2>
      <Row className="pt-4">
        <Col>
          <Controller
            name="client"
            control={control}
            defaultValue={''}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                placeholder={t('forms.client', { ns: 'forms' })}
                label={`${t('forms.client', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={clients.map((c) => ({ id: c.name, name: c.name }))}
                error={errors.client?.message}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="year"
            control={control}
            rules={{ required: true }}
            defaultValue={2016}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                label={`${t('forms.year', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={years.map((y) => ({ id: y, name: y }))}
                error={errors.year?.message}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="month"
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                label={`${t('forms.month', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={MONTHS.map((m, i) => ({ id: i + 1, name: m }))}
                error={errors.year?.message}
              />
            )}
          />
        </Col>
      </Row>
      <div className="d-flex flex-row-reverse pt-4">
        <CustomButton onClick={handleSubmit(onSubmit)} disabled={loading}>
          {t('export.export', { ns: 'exports' })}
        </CustomButton>
      </div>
    </Container>
  )
}

export default SummaryMonthReport

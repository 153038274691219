import React, { useState, useEffect } from "react";

import { getValueStorage, setValueStorage } from "../../helpers";

import { useTranslation } from "react-i18next";

const useSwitchLanguages = () => {
  const [selectLanguage, setSelectLanguage] = useState(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (selectLanguage) {
      i18n.changeLanguage(selectLanguage);
      setValueStorage("selectLanguage", selectLanguage);
    }
  }, [selectLanguage]);

  useEffect(() => {
    const savedLan = getValueStorage("selectLanguage", selectLanguage);
    savedLan ? setSelectLanguage(savedLan) : setSelectLanguage("es");
  }, []);

  return [selectLanguage, setSelectLanguage];
};

export default useSwitchLanguages;

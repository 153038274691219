import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ImTicket, ImDatabase, ImFileExcel } from 'react-icons/im'
import { RiMovie2Line } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
// components
import ExportCard from '../../../components/cards/ExportCard'

const Exports = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('exports')
  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t('exports', { ns: 'header' })}</h2>
      <Row>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('export.loans')} onClick={() => navigate('/administrator/exports/loans')}>
            <ImTicket fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('export.marcxml')} onClick={() => navigate('/administrator/exports/xml-marc')}>
            <ImDatabase fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('export.annualRMC')} onClick={() => navigate('/administrator/exports/report-mc')}>
            <ImDatabase fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('export.summaryMonth')} onClick={() => navigate('/administrator/exports/summary-month')}>
            <ImFileExcel fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard title={t('export.edye')} onClick={() => navigate('/administrator/exports/edye')}>
            <RiMovie2Line fontSize={50} />
          </ExportCard>
        </Col>
      </Row>
    </Container>
  )
}

export default Exports

import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
//api
import { deleteGroupListsById, getListGroupLists } from '../../../api/pathsForHook/products'
// components
import InputCustom from '../../../components/input'
import Table from '../../../components/table'
import { CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
// icons
import { GoPencil } from 'react-icons/go'
import { RiDeleteBinLine } from 'react-icons/ri'
// router
import { Link, useNavigate } from 'react-router-dom'
// translate
import { useTranslation } from 'react-i18next'
// css
import '../../../assets/css/group_lists.scss'
// helpers
import { showToast } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'

const GroupListsAdministradorPage = () => {
  const [groupLists, setGroupLists] = useState(null)
  const [isStatus, setIsStatus] = useState(200)
  const [page, setPage] = useState(1)
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState(null)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [search, setSearch] = useState(null)

  const { t } = useTranslation('groupList')

  const { loading, totalResults, methodGet, methodDelete } = useFetch()

  const handleClose = () => {
    setShowModalDelete(false)
    setSelectedGroupToDelete(null)
  }
  const handleShow = () => setShowModalDelete(true)
  let navigate = useNavigate()

  const getGroupLists = async () => {
    const payload = search ? { search } : {}
    const { data, status } = await methodGet(getListGroupLists(), payload)
    setGroupLists(data)
    setIsStatus(status)
  }

  useEffect(() => {
    if (search != null){
      const timeout = setTimeout(() => {
        getGroupLists()
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [search])

  const openModalDelete = (group) => {
    handleShow()
    setSelectedGroupToDelete(group)
  }

  const goToEdit = (id) => {
    navigate(`/administrator/group-details/${id}`)
  }

  const deleteGroup = async (groupToDelete) => {
    const response = await methodDelete(deleteGroupListsById(groupToDelete.id))
    if (response) {
      showToast('success', t('deletedSuccessful'))
      getGroupLists()
      handleClose()
    }
  }

  useEffect(() => {
    !groupLists && getGroupLists()
  }, [])

  const renderTable = () => {
    if (!groupLists?.results?.length) return null

    const { results } = groupLists
    const hasNext = groupLists?.links?.next ? true : false
    const hasPrevious = groupLists?.links?.previous ? true : false

    const handleEditClick = (id) => goToEdit(id)
    const handleDeleteClick = (item) => openModalDelete(item)

    return (
      <Table
        header={t('headerTableListGroup', { returnObjects: true })}
        page={page}
        changePage={setPage}
        next={hasNext}
        previous={hasPrevious}
      >
        {results.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.list_number}</td>
            <td>{item.client_number}</td>
            <td className="d-flex justify-content-center actions-row-buttons">
              <span className="action-btn-edit " onClick={() => handleEditClick(item.id)}>
                <GoPencil className="icon icon-edit " />
              </span>

              <span className="action-btn-delete " onClick={() => handleDeleteClick(item)}>
                <RiDeleteBinLine className="icon icon-delete" />
              </span>
            </td>
          </tr>
        ))}
      </Table>
    )
  }

  const render = () => {
    return loading || groupLists === null ? <Loading /> : renderTable()
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t('listGroup')}</h2>
      <Row className="my-2 w-100 d-flex justify-content-between">
        <Col lg={5} md={8} sm={8} xs={12}>
          <InputCustom
            placeholder={t('searchLists')}
            className="border border-gray "
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col lg={5} md={4} xs={12} className="text-md-end">
          <Link to="/administrator/clients-groups">
            <button className="online-cprimary mt-0 me-2 btn btn-outline-sucess ">{t('assignClients')}</button>
          </Link>
          <Link to="/administrator/group-details/new">
            <button className="online-cprimary mt-0 btn btn-outline-sucess ">{t('createNewGroup')}</button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          {isStatus === 404 || isStatus === 500 ? (
            <div className="box-message-plug">
              <p>{isStatus === 404 ? t('errorDataNotFound') : t('serverError')}</p>
            </div>
          ) : null}
          {totalResults === 0 && !loading && (
            <Col md={12} className="box-message-plug">
              <p className="d-flex justify-content-center m-3">{t('emptyListsGroup')}</p>
            </Col>
          )}
          {render()}
        </Col>
      </Row>
      <Modal show={showModalDelete} onHide={handleClose} centered>
        <Modal.Body>Borrar "{`${selectedGroupToDelete?.name}`}" ?</Modal.Body>
        <Modal.Footer>
          <CustomButton onClick={() => deleteGroup(selectedGroupToDelete)} className=" mt-0 btn btn-outline-sucess ">
            Borrar
          </CustomButton>
          <CustomButton onClick={handleClose} className=" online-cprimary mt-0 btn btn-outline-sucess  ">
            Cancelar
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default GroupListsAdministradorPage

import React from "react";
import { Outlet, useLocation } from "react-router-dom";

// Own components
import Footer from "../components/footer";
import Header from "../components/header";
import { ToastContainer } from "react-toastify";
import "../assets/css/layouts.scss";
import { getValueStorage } from "../helpers";

const pathname = window.location.pathname === "/clients";

// console.log("holi", pathname);

const DefaultLayout = (props) => {
  return (
    <>
      <Header />
      <ToastContainer />
      <main className={`${pathname ? "height-main " : ""}main`}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
export default DefaultLayout;

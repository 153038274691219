import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from "axios";

// components
import Table from '../../../../components/table'
import SelectCustom from '../../../../components/select'
import ButtonGoBack from '../../../../components/buttons/GoBackButton'
import NoInfo from '../../../../components/noInfo'
import Input from '../../../../components/input'
import Button from '../../../../components/buttons/CustomButton'

// hook
import useFetch from '../../../../hooks/useFetch'
// api
import { getAdminStatisticsFilters } from '../../../../api/pathsForHook/administrator'
import { getLoanRaking } from '../../../../api/pathsForHook/loans'
// constants
import { API_EFILM } from '../../../../constants'
// icons
import { TiDownloadOutline } from 'react-icons/ti'

const GeneralStatisticsPage = () => {
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([])
  const [clients, setClients] = useState([])
  const [providers, setProviders] = useState([])
  const [filters, setFilters] = useState({ size: 50 })
  const [ranking, setRanking] = useState([])
  const [disabledBtn, setDisabledBtn] = useState(false)

  const { methodGet } = useFetch()

  const { t, i18n } = useTranslation('statistics')

  useEffect(() => {
    const fetchFilter = async () => {
      const { data } = await methodGet(getAdminStatisticsFilters())
      if (data) {
        setYears(data.years.map((item) => ({ id: item, name: item })))
        setMonths(data.months.map((item, i) => ({ id: i + 1, name: item })))
        setClients(data.clients)
        setProviders(data.providers)
      }
    }
    fetchFilter()
    fetchRaking()
  }, [])

  const fetchRaking = async () => {
    const { data } = await methodGet(getLoanRaking(), filters)
    if (data) {
      setRanking(data)
    }
  }

  const exportRankingContent = async () => {
    setDisabledBtn(true)
    const params = new URLSearchParams(filters).toString()
    axios({
      url: API_EFILM + 'statistics/loans/export-ranking/?' + params,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'JWT ' + localStorage.getItem('tokenAuth'),
        'Accept-Language': i18n.language,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `ranking_${new Date().toString()}.xls`)
      document.body.appendChild(link)
      link.click()
      setDisabledBtn(false)
    })
  }

  return (
    <Container className="mb-3 mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('generalStatistics')}</h2>
      <Row>
        <Col lg={3}>
          <SelectCustom
            placeholder={t('allClients', { ns: 'homeAdmin' })}
            label={t('clients', { ns: 'homeAdmin' })}
            options={clients}
            handleSelect={(value) => setFilters({ ...filters, client: value })}
          />
        </Col>
        <Col lg={2}>
          <SelectCustom
            placeholder={t('allYears', { ns: 'homeAdmin' })}
            label={t('years', { ns: 'homeAdmin' })}
            options={years}
            handleSelect={(value) => setFilters({ ...filters, year: value })}
          />
        </Col>
        <Col lg={2}>
          <SelectCustom
            placeholder={t('allMonths', { ns: 'homeAdmin' })}
            label={t('months', { ns: 'homeAdmin' })}
            options={months}
            handleSelect={(value) => setFilters({ ...filters, month: value })}
          />
        </Col>
        <Col lg={3}>
          <SelectCustom
            placeholder={t('allProviders', { ns: 'homeAdmin' })}
            label={t('providers', { ns: 'homeAdmin' })}
            options={providers}
            handleSelect={(value) => setFilters({ ...filters, provider: value })}
          />
        </Col>
        <Col lg={2}>
          <Input
            label={t('resultNumber', { ns: 'homeAdmin' })}
            placeholder={t('resultNumber', { ns: 'homeAdmin' })}
            onChange={(e) => setFilters({ ...filters, size: e.target.value })}
            type="number"
            value={filters?.size}
          />
        </Col>
        <Col md={12}>
          <Button className="mt-3" onClick={fetchRaking}>
            {t('applyFilters')}
          </Button>
          <Button className="online-cprimary ms-2 mt-3" disabled={disabledBtn} onClick={exportRankingContent}>
            {t('export', { ns: 'home' })}
            {disabledBtn ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <TiDownloadOutline />
            )}
          </Button>
        </Col>
        {ranking?.length > 0 ? (
          <Col lg={6} className="mt-4">
            <Row>
              <Col className="d-flex justify-content-between mb-4">
                <h4 className="d-inline">Top {filters?.size}</h4>
              </Col>
            </Row>
            <Table header={[t('title', { ns: 'common' }), t('loans', { ns: 'homeAdmin' })]}>
              {ranking?.map((item, i) => (
                <tr key={i}>
                  <td>{item.product__name}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </Table>
          </Col>
        ) : (
          <NoInfo />
        )}
      </Row>
    </Container>
  )
}

export default GeneralStatisticsPage

import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'

import ChartContainer from './ChartContainer'

const LineChart = ({ data, labels, title, loading, download, enabledDownload, ...props }) => {
  const initialData = {
    labels: labels,
    datasets: data?.map((item) => ({
      label: loading ? 'Loading...' : item.label,
      data: item.data,
      backgroundColor: loading ? ['#8F839F'] : item.color,
    })),
  }

  const options = {
    elements: {
      point: {
        radius: 6,
      },
      line: {
        borderWidth: 5,
        tension: 0.25,
      },
    },
    plugins: {
      legend: {
        display: loading ? false : true,
        position: loading ? 'right' : 'top',
      },
      tooltip: {
        enabled: loading ? false : true,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  }
  return (
    <ChartContainer enabledDownload={enabledDownload} title={title} download={download} className="line__chart">
      <Line options={options} data={initialData} {...props} />
    </ChartContainer>
  )
}

LineChart.propTypes = {
  colors: PropTypes.array,
  data: PropTypes.array,
  labels: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.string,
  download: PropTypes.func,
  enabledDownload: PropTypes.bool,
}
export default LineChart

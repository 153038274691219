import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { clientName } from "../../constants";

import { UserContext } from "../../contexts";
import { setValueStorage } from "../../helpers";

import { Navbar, Nav, Row, Col } from "react-bootstrap";

import Select from "../../components/select";

import useSwitchLanguages from "../../hooks/useSwitchLanguages/switchLanguageHook";
import { createLanguageOptions } from "../../utilities";

import { useTranslation } from "react-i18next";

import { getValueStorage } from "../../helpers";

import "../../assets/css/styles.scss";

const Header = () => {
  const userContext = useContext(UserContext);
  const [selectLanguage, setSelectLanguage] = useSwitchLanguages();
  let navigate = useNavigate();
  const { t } = useTranslation(["header", "groupList"]);

  const logout = () => {
    setValueStorage("tokenAuth", "");
    userContext.setIsAuth(false);
    navigate("/");
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bgd-primary"
        variant="dark"
      >
        <Navbar.Brand className="p-3">
          <Link to="/" className="text-decoration-none text-white">
            {clientName}
          </Link>{" "}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="me-sm-3 button-hamburger"
        />
        <Navbar.Collapse id="responsive-navbar-nav ">
          <Nav className="mr-auto flex-grow-1 ms-sm-3">
            {userContext.userInfo?.profile === 3 &&
              userContext.userInfo?.providers[0] !== "pinna" && (
                <>
                  <Link
                    to="/provider/content"
                    className="text-decoration-none me-3  color-text"
                  >
                    {t("content").toUpperCase()}
                  </Link>
                  <Link
                    to="/provider/clients"
                    className="text-decoration-none  me-3 color-text"
                  >
                    {t("clients").toUpperCase()}
                  </Link>
                </>
              )}
          </Nav>

          <Nav className="flex-grow-1 d-flex justify-content-lg-end  ms-sm-3">
            <Nav.Link onClick={() => logout()} href="#">
              {t("logOut")} <FiLogOut />
            </Nav.Link>
          </Nav>
          <Nav className=" width-45-px ml-10-px me-lg-3">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="language-select">
                  <Select
                    key="select-language"
                    options={createLanguageOptions()}
                    handleSelect={setSelectLanguage}
                    value={selectLanguage}
                    defaultValue={getValueStorage("selectLanguage")}
                  />
                </div>
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;

import React, { useContext, useState } from 'react'
import { Container, Row, Col, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { HiOutlineBell } from 'react-icons/hi'
// contexts
import { GeneralContext } from '../../../contexts/GeneralContext'
// components
import SelectCustom from '../../../components/select'
import { CustomButton } from '../../../components/buttons'
import Input from '../../../components/input'
import ButtonGoBack from '../../../components/buttons/GoBackButton'

import useFetch from '../../../hooks/useFetch'
import { exportLoansCSV } from '../../../api/pathsForHook/loans'

import { showToast } from '../../../helpers'

const ExportLaons = () => {
  const [enabled, setEnabled] = useState(true)

  const { clients } = useContext(GeneralContext)
  const { methodPost } = useFetch()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation(['exports', 'forms', 'common'])

  const onSubmit = async (data) => {
    setEnabled(false)
    const rs = await methodPost(exportLoansCSV(), data)
    if (rs.status == 200) {
      showToast('success', rs.data?.msg, 5000)
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
    setEnabled(true)
  }
  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('export.loans', { ns: 'exports' })}</h2>
      <Row>
        <Col>
          <Alert variant="info d-flex flex-direction-row">
            <HiOutlineBell fontSize={40} />
            <div className="ms-3">
              Seleccione un cliente e ingrese un correo electrónico, las fechas son opcionales. <br />
              Cuando se termine de generar el excel con los préstamos te enviaremos un correo electrónico con un enlace
              para que puedas descargar el excel.
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <Controller
            name="client"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                label={`${t('forms.client', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={clients.map((c) => ({ id: c.id, name: c.name }))}
                error={errors.client?.message}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="from_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('forms.dateFrom', { ns: 'forms' })}
                value={value}
                onChange={onChange}
                placeholder={t('forms.dateFrom', { ns: 'forms' })}
                type="date"
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="to_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('forms.dateTo', { ns: 'forms' })}
                value={value}
                onChange={onChange}
                placeholder={t('forms.dateTo', { ns: 'forms' })}
                type="date"
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('forms.requiredField', { ns: 'forms' }),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('forms.invalidEmail', { ns: 'forms' }),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={`${t('forms.email', { ns: 'forms' })} *`}
                value={value}
                onChange={onChange}
                placeholder={t('forms.email', { ns: 'forms' })}
                type="email"
                error={errors.email?.message}
              />
            )}
          />
        </Col>
      </Row>
      <div className="d-flex flex-row-reverse pt-4">
        <CustomButton onClick={handleSubmit(onSubmit)} disabled={!enabled}>
          {t('export.export', { ns: 'exports' })}
        </CustomButton>
      </div>
    </Container>
  )
}

export default ExportLaons

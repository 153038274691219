import React from "react";

import "./App.css";

// routes
import MainRoute from "./routes";

// own contexts
// import UserContextProvider from "./contexts/UserContext";
import { UserProvider } from "./providers";
import GeneralProvider from "./contexts/GeneralContext";
import AdminContext from "./contexts/AdminContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/styles.scss";

const App = () => {
  return (
    <div className="App">
      <GeneralProvider>
        <AdminContext>
          <UserProvider>
            <MainRoute />
          </UserProvider>
        </AdminContext>
      </GeneralProvider>
    </div>
  );
};

export default App;

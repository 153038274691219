import PropTypes from "prop-types";
import React from "react";

import { Card } from "react-bootstrap";

const InfoCard = ({ title, data }) => {
  return (
    <Card className="card__info">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle>{data}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

InfoCard.propTypes = {
  data: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

export default InfoCard;

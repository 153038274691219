import React, { useEffect, useState } from "react";
//components
import { Row } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
//Translations
import { useTranslation } from "react-i18next";
// own components
// import ChartSkeleton from "../Skeleton/ChartSkeleton";
import ChartContainer from "./ChartContainer";

const PieChart = ({ data, width, height, title, labels, colors, loading }) => {
  const { t } = useTranslation("common");

  const initialData = {
    labels: ['Loading','Loading','Loading','Loading'],
    datasets: [
      {
        label:'Loading',
        data: [60,80,40,88],
        backgroundColor: loading ? ["#00000022"] : ["#0000B5"],
      }
    ]
  }

  const [dataTransformed, setDataTransformed] = useState(initialData);

  useEffect(() => {
    if (data) {
      setDataTransformed({
        labels: labels,
        datasets: [
          {
            label: "Aggregated Data",
            data: data,
            backgroundColor: colors || ["#f5ff2c8A", "#79BBD28A","#3d87468A", "#e923288A", "#00ff2c8A", "#3300dd8A","#3d80ff8A", "#ffffff8A"],
          },
        ],
      });
    }
  }, [data]);

  const skeletonData = {
    labels: loading ? false : labels,
    datasets: [
      {
        label: false,
        data: [10000, 8000],
        backgroundColor: ["rgb(139, 139, 140, 0.2)", "rgb(140, 151, 151, 0.4)"],
      },
    ],
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 2,
        borderColor: "rgb(234, 230, 221)",
      },
    },
    plugins: {
      legend: {
        display: loading ? false : true,
        /*  position: !isPrincipal ? 'right' : 'top', */
      },
      tooltip:{
        enabled: loading ? false : true

      }
    },
    maintainAspectRatio: false,
    responsive: true,
  };


  return (
    <ChartContainer title={title} isCircular={true}>
        <Pie
          options={options}
          data={loading ? skeletonData : dataTransformed }
          height={500}
          />
    </ChartContainer>
  );
};
export default PieChart;
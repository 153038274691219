import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap'
// apis
import { getPinnaInfo } from '../../api/pathsForHook/clients'
import axios from 'axios'
import { API_EFILM } from '../../constants'
// own hooks
import useFetch from '../../hooks/useFetch'
// components
import Table from '../../components/table'
import { CustomButton } from '../../components/buttons'
import Loading from '../../components/Loading'
import PieChart from '../../components/charts/PieChart'

//translations
import { useTranslation } from 'react-i18next'
//CSS and icons
import '../../assets/css/group_lists.scss'
import { BiRefresh } from 'react-icons/bi'
import { TiDownloadOutline } from 'react-icons/ti'
// helpers
import { showToast } from '../../helpers'

const PinnaPage = () => {
  const { t, i18n } = useTranslation(['pinna', 'common'])

  const [pinnaClients, setPinnaClients] = useState(null)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [aggregatedTotalMinutes, setAggregatedTotalMinutes] = useState(null)
  const [aggregatedMinutesConsumed, setAggregatedMinutesConsumed] = useState(null)
  const [aggregatedMinutesLeft, setAggregatedMinutesLeft] = useState(null)
  const [totalUsers, setTotalUsers] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { methodGet } = useFetch()

  useEffect(() => {
    getPinnaClients()
  }, [])

  useEffect(() => {
    pinnaClients && handleAggregateInfo()
  }, [pinnaClients])

  const handleAggregateInfo = () => {
    if (pinnaClients === 'noInfo') {
      return
    }

    const totalMinutes = handleSum(pinnaClients, 'hired_minutes')
    const minutesConsumed = handleSum(pinnaClients, 'consumption')
    const patronsCount = handleSum(pinnaClients, 'user_quantity')
    const minutesLeft = totalMinutes - minutesConsumed

    if (minutesLeft > 0) {
      setAggregatedMinutesLeft(minutesLeft)
    } else {
      setAggregatedMinutesLeft('-')
    }

    setAggregatedTotalMinutes(totalMinutes)
    setAggregatedMinutesConsumed(minutesConsumed)
    setTotalUsers(patronsCount)
    setIsLoading(false)
  }

  const handleSum = (clients, key) => {
    const filteredMinutes = clients?.map((client) => client[key] || 0)
    const sum = filteredMinutes.reduce((total, minutes) => total + parseInt(minutes), 0)
    return sum
  }

  const getPinnaClients = async () => {
    let isDemo = false
    const response = await methodGet(getPinnaInfo(isDemo))
    if (response) {
      setPinnaClients(response.data)
    } else {
      setPinnaClients('noInfo')
      showToast('error', t('error', { ns: 'common' }))
      console.log('fallo al cargar los clientes')
    }
  }

  const exportLoanContent = async () => {
    setDisabledBtn(true)
    axios({
      url: API_EFILM + 'statistics/pinna/export-summary-clients/',
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: 'JWT ' + localStorage.getItem('tokenAuth'),
        'Accept-Language': i18n.language,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `loans_${new Date().toString()}.xls`)
      document.body.appendChild(link)
      link.click()
      setDisabledBtn(false)
    })
  }

  const refreshData = () => {
    setIsLoading(true)
    getPinnaClients()
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <Row className="mt-5 ">
        <Col lg={11}>
          <h1>Pinna</h1>
        </Col>
        <Col lg={1}>
          <Button onClick={() => refreshData()} variant="success">
            <BiRefresh size="25" />
          </Button>
        </Col>
      </Row>
      {!pinnaClients || isLoading ? (
        <Loading />
      ) : pinnaClients === 'noInfo' ? (
        <p>{t('noData', { ns: 'common' })}</p>
      ) : (
        <>
          <Row className="pt-lg-5 pt-xs-5 ">
            <Col md={5}>
              <div>
                <h3>{t('summary', { ns: 'common' })}</h3>
                <div>
                  <div className="col color-headline">
                    {pinnaClients?.length} {t('hiredClientsPinna')}
                  </div>
                  {aggregatedMinutesConsumed && aggregatedTotalMinutes && (
                    <div className="col color-headline">
                      {t('aggregateUsage')} : {aggregatedMinutesConsumed} / {aggregatedTotalMinutes} {t('minutes')}
                    </div>
                  )}
                  {totalUsers && (
                    <div className="col color-headline">
                      {totalUsers} {t('patrons')}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {aggregatedMinutesConsumed && aggregatedTotalMinutes && (
              <Col md={6} className="section-polar-chart d-flex justify-content-center">
                <PieChart
                  data={pinnaClients !== 'noInfo' ? [aggregatedMinutesConsumed, aggregatedMinutesLeft] : null}
                  labels={t('pinnaLabels', { returnObjects: true })}
                  width={250}
                  height={250}
                  colors={['#7Fc7F0', '#93c90e']}
                />
              </Col>
            )}
          </Row>
          <Col>
            <Table header={t('headerPinnaTable', { returnObjects: true })}>
              {pinnaClients?.map((client, index) => (
                <tr key={index}>
                  <td>{client?.name}</td>
                  <td>{client?.area}</td>
                  <td>{client?.size}</td>
                  <td>{client?.pinna_tier}</td>
                  <td>{client?.hired_minutes && client?.hired_minutes}</td>
                  <td>{client?.consumption && client?.consumption}</td>
                  <td>{client?.user_quantity && client?.user_quantity}</td>
                </tr>
              ))}
            </Table>
            <CustomButton disabled={disabledBtn} className="online-cprimary mb-3" onClick={exportLoanContent}>
              {t('export', { ns: 'home' })}
              {disabledBtn ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                <TiDownloadOutline />
              )}
            </CustomButton>
          </Col>
        </>
      )}
    </Container>
  )
}

export default PinnaPage

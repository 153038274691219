import { useLocation, Navigate } from 'react-router-dom';
import { getValueStorage } from '../helpers';


const RequireAuth = ({userNumber,children}) => {
    const userInfo = JSON.parse(getValueStorage('userInfo'))
    // const { isAuth } = useContext(UserContext);
    const location = useLocation()
    // console.log('USER INFO', userInfo);

    if(userInfo?.profile === userNumber) return children
        else return <Navigate to='/login' state={{from:location}}/>
}

export default RequireAuth
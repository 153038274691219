import React from 'react'
// router
import { useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'

const BreadCrumb = ({ activeItemName = 'unknown', prevPageName = 'unknown', route = '#', ...props }) => {
  let navigate = useNavigate()

  return (
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate(route)}>{prevPageName}</Breadcrumb.Item>
      <Breadcrumb.Item active>{activeItemName}</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BreadCrumb

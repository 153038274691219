import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiMovie2Line, RiSlideshow3Line, RiListCheck } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6"
import { useNavigate } from "react-router-dom";
// components
import ExportCard from "../../../components/cards/ExportCard";

const ContentManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("management");
  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t("contentManagement", { ns: "header" })}</h2>
      <Row>
        <Col className="d-flex justify-content-center">
          <ExportCard
            title={t("productManagement")}
            onClick={() =>
              navigate("/administrator/content-management/products-management")
            }
          >
            <RiMovie2Line fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard
            title={t("collectionManagement")}
            onClick={() =>
              navigate("/administrator/content-management/collections")
            }
          >
            <RiSlideshow3Line fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard
            title={t("listManagement")}
            onClick={() => navigate("/administrator/content-management/lists")}
          >
            <RiListCheck fontSize={50} />
          </ExportCard>
        </Col>
        <Col className="d-flex justify-content-center">
          <ExportCard
            title={t("activateAll")}
            onClick={() => navigate("/administrator/content-management/activate-all")}
          >
            <FaCheck fontSize={50} />
          </ExportCard>
        </Col>
      </Row>
    </Container>
  );
};

export default ContentManagement;

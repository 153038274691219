import { createContext, useMemo, useState } from "react";

export const AdminContext = createContext(null);

export const AdminProvider = ({ children }) => {
  const [adminState, setAdminState] = useState({
    collections: {},
    products: {},
    automaticLists: {},
  });

  const values = useMemo(() => ({ adminState, setAdminState }), [adminState]);

  return (
    <AdminContext.Provider value={values}>{children}</AdminContext.Provider>
  );
};

export default AdminProvider;

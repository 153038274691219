import React, { useState, useEffect } from "react";
import { Table, Button, Modal } from "semantic-ui-react";
import { format, differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
export const COIN = process.env.REACT_APP_COIN;

const TableComponent = ({ data }) => {
  const { t } = useTranslation(["clients"]);
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortedData, setSortedData] = useState(null);

  useEffect(() => {
    const newData =
      sortKey &&
      data?.sort((a, b) => {
        if (!a[sortKey] && !b[sortKey]) return 0;
        if (!a[sortKey]) return sortDirection === "ascending" ? -1 : 1;
        if (!b[sortKey]) return sortDirection === "ascending" ? 1 : -1;

        return sortKey !== "consumption"
          ? sortDirection === "ascending"
            ? a[sortKey].localeCompare(b[sortKey])
            : b[sortKey].localeCompare(a[sortKey])
          : sortDirection === "ascending"
          ? a.consumption.amount - b.consumption.amount
          : b.consumption.amount - a.consumption.amount;
      });
    let result = newData ? newData : data;
    setSortedData(result);
    /*  console.log("result", result); */
  }, [data, sortKey, sortDirection]);

  const sortIcons = {
    ascending: "↑",
    descending: "↓",
    neutral: "≡",
  };

  const buttonSortStyles = {
    float: "right",
    backgroundColor: "transparent",
    border: "none",
    width: "25px",
    height: "25px",
    paddingLeft: "5px",
    borderRadius: "5px",
    fontWeight: "bold",
  };

  const datesAndNumbersCellsStyle = {
    fontFamily: "monospace",
    textAlign: "right",
  };

  const handleSort = (key) => {
    setSortKey(key);
    setSortDirection(
      sortKey === key
        ? sortDirection === "ascending"
          ? "descending"
          : "ascending"
        : "ascending"
    );
  };

  const handleCountExpirationDays = (date) => {
    const days = differenceInDays(date, new Date());
    return days === 2
      ? t("tomorrow")
      : days > 365
      ? t("in more than a year")
      : days <= 1
      ? t("today")
      : `${"in"} ${days} ${t("days")}`;
  };

  useEffect(() => {
    handleSort(sortKey);
  }, [sortKey]);

  return (
    <>
      <Table className="table-clients-by-provider">
        <Table.Header>
          <Table.Row style={{ userSelect: "none" }}>
            <Table.HeaderCell
              sorted={sortKey === "platform_name" ? sortDirection : null}
              onClick={() => handleSort("platform_name")}
              style={{ cursor: "pointer" }}
            >
              Name
              <Button style={buttonSortStyles}>
                {
                  sortIcons[
                    sortKey === "platform_name" ? sortDirection : "neutral"
                  ]
                }
              </Button>
            </Table.HeaderCell>
            {/* <Table.HeaderCell>Libraries</Table.HeaderCell> */}
            <Table.HeaderCell>Active packs</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortKey === "consumption" ? sortDirection : null}
              onClick={() => handleSort("consumption")}
              style={{ textAlign: "right", cursor: "pointer" }}
            >
              Consumption
              <Button style={buttonSortStyles}>
                {
                  sortIcons[
                    sortKey === "consumption" ? sortDirection : "neutral"
                  ]
                }
              </Button>
            </Table.HeaderCell>
            <Table.HeaderCell>Tier</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortKey === "created" ? sortDirection : null}
              onClick={() => handleSort("created")}
              style={{ textAlign: "right", cursor: "pointer" }}
            >
              Contract sign date
              <Button style={buttonSortStyles}>
                {sortIcons[sortKey === "created" ? sortDirection : "neutral"]}
              </Button>
            </Table.HeaderCell>
            {/* <Table.HeaderCell
              sorted={sortKey === "expired" ? sortDirection : null}
              onClick={() => handleSort("expired")}
              style={{ textAlign: "right", cursor: "pointer" }}
            >
              Expiration date
              <Button style={buttonSortStyles}>
                {sortIcons[sortKey === "expired" ? sortDirection : "neutral"]}
              </Button>
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData?.map((item, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>
                <big>{item?.client?.platform_name}</big>
              </Table.Cell>
              {/* <Table.Cell>
                <div style={{ height: "100px", overflowY: "auto" }}>
                  {item?.libraries?.map((library, idx) => (
                    <div key={idx}>{library}</div>
                  ))}
                </div>
              </Table.Cell> */}
              <Table.Cell style={{ textAlign: "left" }}>
                {item?.tier?.packs?.map(
                  (pack, idx) =>
                    `${pack}${
                      item?.tier?.packs?.length === idx + 1 ? "." : ", "
                    } `
                )}
              </Table.Cell>
              <Table.Cell style={datesAndNumbersCellsStyle}>
                {item?.consumption?.count && item?.consumption?.amount ? (
                  <>
                    <p>Cantidad: {item?.consumption?.count?.toFixed(0)} </p>
                    <p>
                      Importe: {item?.consumption?.amount?.toFixed(2) + COIN}{" "}
                    </p>
                  </>
                ) : (
                  <small>Tarifa plana</small>
                )}
              </Table.Cell>
              <Table.Cell style={{ textAlign: "left" }}>
                Tier #{item?.tier?.id}
              </Table.Cell>
              <Table.Cell style={datesAndNumbersCellsStyle}>
                {item?.created && format(new Date(item?.created), "dd.MM.yyyy")}
              </Table.Cell>
              {/* <Table.Cell style={datesAndNumbersCellsStyle}>
                {item?.expired && (
                  <>
                    <p>{format(new Date(item?.expired), "dd.MM.yyyy")}</p>
                    <small>
                      ({handleCountExpirationDays(new Date(item?.expired))})
                    </small>
                  </>
                )}
              </Table.Cell> */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default TableComponent;

import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
// own components
import Footer from "../components/footer";
import AdminSideBar from "../components/SideBar/AdminSideBar";

import "../assets/css/layouts.scss";

const AdminLayout = () => {
  return (
    <Container fluid className="admin__layout">
      <Row>
        <Col xs={2}>
          <AdminSideBar />
        </Col>
        <Col xs={10}>
          <main>
            <Outlet />
          </main>
        </Col>
      </Row>
      <Footer />
      <ToastContainer />
    </Container>
  );
};

export default AdminLayout;

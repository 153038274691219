import { useEffect } from 'react'

const useSearch = ({ searchTerm, delay = 500, onProcessSearch }) => {
  useEffect(() => {
    let debouncer = setTimeout(() => {
      onProcessSearch()
    }, delay)
    return () => {
      clearTimeout(debouncer)
    }
  }, [searchTerm])
}

export default useSearch

import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import logo from "../../assets/img/theshelf.png";
import { logoShelf } from "../../constants";

// own components
import { CustomCard } from "../../components/cards";
import Input from "../../components/input";
import Select from "../../components/select";
import { CustomButton } from "../../components/buttons";

// calls api
import { authUser } from "../../api/authUser";

import { UserContext } from "../../contexts";

import { setValueStorage, getValueStorage } from "../../helpers";

//translations
import useSwitchLanguages from "../../hooks/useSwitchLanguages/switchLanguageHook";
import { createLanguageOptions } from "../../utilities";
import { useTranslation } from "react-i18next";

import "../../assets/css/styles.scss";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [msgError, setMsgError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuth, setUserInfo } = useContext(UserContext);
  const [selectLanguage, setSelectLanguage] = useSwitchLanguages();
  const { t } = useTranslation("login");

  const fromPage = location?.state?.from?.pathname;

  const handleSubmit = async () => {
    if (user === "" || password === "") {
      setMsgError(t("fieldsRequired"));
    } else {
      const response = await authUser({ username: user, password });
      if (response.status === 200) {
        const { token, refresh, userData } = response;
        setValueStorage("userInfo", JSON.stringify(userData));
        setValueStorage("tokenAuth", token);
        setValueStorage("refreshAuth", refresh);
        setUserInfo(userData);
        setIsAuth(true);

        const nextPath = () => {
          if (fromPage) return fromPage;
          else if (userData.profile === 4) return "/administrator";
          else if (userData.profile === 3) return "/provider";
          else return;
        };

        navigate(nextPath());
      } else {
        setMsgError(t("usernamePasswordIncorrect"));
      }
    }
  };

  return (
    <>
      <Row className="mt-3 w-100 position-absolute fixed-top">
        <Col className="d-flex justify-content-end" lg={12} md={12} sm={12}>
          <Select
            key="select-language"
            options={createLanguageOptions()}
            handleSelect={setSelectLanguage}
            value={getValueStorage("selectLanguage")}
          />
        </Col>
      </Row>
      <div className="container">
        <Row className="d-flex justify-content-center  ">
          <Col lg={4} md={5} xs={13}>
            <CustomCard
              title={t("logIn")}
              className="bgd-secondary p-3"
              img={logoShelf}
            >
              <Input
                value={user}
                onChange={(e) => setUser(e.target.value)}
                type="text"
                placeholder={t("username")}
                className="my-3"
              />
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder={t("password")}
                className="mb-3"
              />
              {msgError !== "" ? (
                <Row>
                  <Col md={12}>
                    <div className="msg-error">{msgError}</div>
                  </Col>
                </Row>
              ) : null}

              <Col
                lg={5}
                md={8}
                xs={8}
                className="justify-content-end d-flex w-100"
              >
                <CustomButton
                  className="online-cprimary btn btn-outline-sucess "
                  onClick={handleSubmit}
                >
                  {t("logIn")}
                </CustomButton>
              </Col>
            </CustomCard>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;

import PropTypes from "prop-types";
import React from "react";
import { PolarArea } from "react-chartjs-2";
import ChartSkeleton from "../Skeleton/ChartSkeleton";
import ChartContainer from "./ChartContainer";

const PolarChart = ({ labels, data, title, loading }) => {
  const dataChart = {
    labels: loading ? ['First', 'Second', 'Third', 'Fourth','Fifth'] : labels,
    datasets: [
      {
        label: loading ? 'Some fu**ings numbers' : title,
        data: loading ? [3,2,4,5,3] : data,
        backgroundColor: loading ? ['#00000040'] :  [
          "#79BBD28A",
          "#47C2B48A",
          "#D29B798A",
          "#22675E8A",
          "#8892D78A",
          "#7DD4D18A",
          "#B8E6B38A",
          "#E8D2BA8A",
        ],
      },
    ],
  };
  const options = {
    elements: {
      arc: {
        borderWidth: 2,
        borderColor: "rgb(234, 230, 221)",
      },
    },
    plugins: {
      legend: {
        display: loading ? false : true,
        position: loading ? 'right' : 'top',
      },
      tooltip:{
        enabled: loading ? false : true
      },
      
    },
    maintainAspectRatio: false,
    responsive: true
  }

  // return data.length > 0 ? <PolarArea data={dataChart} /> : null;

  return (
    <ChartContainer title={title} className="polar__chart" isCircular={true}>
      <PolarArea
        data={dataChart}
        options={options}
        height={500}
        />
    </ChartContainer>
  )
};


export default PolarChart;

import { UserContext } from "../contexts";
import React, { useState, useEffect } from "react";
import { getValueStorage } from "../helpers";

const { Provider } = UserContext;

const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const checkLogged = getValueStorage("tokenAuth") ? true : false;
    const user = getValueStorage("userInfo")
      ? JSON.parse(getValueStorage("userInfo"))
      : {};
    setIsAuth(checkLogged);
    setUserInfo(user);
  }, []);

  const userContextValue = {
    userInfo,
    setUserInfo,
    isAuth,
    setIsAuth,
    hasUser: userInfo != null,
  };

  return <Provider value={userContextValue}>{children}</Provider>;
};

export default UserProvider;

import React, { useState, useEffect } from 'react'
import { Row, Container, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BiRefresh } from 'react-icons/bi'
// own hooks
import useFetch from '../../../../hooks/useFetch'
// own components
import BarChart from '../../../../components/charts/BarChart'
import SelectCustom from '../../../../components/select'
import ButtonGoBack from '../../../../components/buttons/GoBackButton'
import InfoCard from '../../../../components/cards/InfoCard'

// styles
import '../../../../assets/css/charts.scss'
// api
import {
  getAdminExportAmount30Days,
  getAdminExportLoans30Days,
  getAdminStatistics,
  getAdminStatisticsFilters,
} from '../../../../api/pathsForHook/administrator'
import LineChart from '../../../../components/charts/LineChart'
import { COIN, COLORS } from '../../../../constants'
import { downloadFile } from '../../../../helpers'

const Statistics = () => {
  const { t } = useTranslation('homeAdmin')

  const [statisticsData, setStatisticsData] = useState([])
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([])
  const [clients, setClients] = useState([])
  const [devices, setDevices] = useState([])
  const [providers, setProviders] = useState([])
  const [filters, setFilters] = useState({})
  const [enabledDownload, setEnabledDownload] = useState(true)
  const { loading, methodGet } = useFetch()

  useEffect(() => {
    fetchFilter()
    fetchData()
  }, [])

  useEffect(() => {
    if (Object.keys(filters).length > 0) fetchData()
  }, [filters])

  const fetchFilter = async () => {
    const { data } = await methodGet(getAdminStatisticsFilters())
    if (data) {
      setYears(data.years.map((item) => ({ id: item, name: item })))
      setMonths(data.months.map((item, i) => ({ id: i + 1, name: item })))
      setClients(data.clients)
      setDevices(data.devices.map((item) => ({ id: item[0], name: item[1] })))
      setProviders(data.providers)
    }
  }

  const fetchData = async () => {
    const { data } = await methodGet(getAdminStatistics(), filters)
    if (data) {
      setStatisticsData(data)
    }
  }

  const download30Days = () => {
    const params = new URLSearchParams(filters).toString()
    setEnabledDownload(false)
    downloadFile(
      getAdminExportLoans30Days() + '?' + params,
      filters,
      localStorage.getItem('tokenAuth'),
      `loans_30days_${filters.client}`,
      setEnabledDownload,
      'xls',
    )
  }

  const downloadAmount30Days = () => {
    const params = new URLSearchParams(filters).toString()
    setEnabledDownload(false)
    downloadFile(
      getAdminExportAmount30Days() + '?' + params,
      filters,
      localStorage.getItem('tokenAuth'),
      `amount_30days_${filters.client}`,
      setEnabledDownload,
      'xls',
    )
  }

  const sumAllValues = (param) => {
    if (Object.keys(param).length > 0) {
      return Object.keys(param)
        .map((key) => param[key])
        .reduce((prev, curr) => prev + curr)
    }
    return 0
  }

  return (
    <Container className="d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Row className="mt-5 ">
        <Col lg={11}>
          <h1>{t('statistics')}</h1>
        </Col>
        <Col lg={1}>
          <Button onClick={() => fetchData()} variant="success">
            <BiRefresh size="25" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <SelectCustom
            placeholder={t('allClients')}
            label={t('clients')}
            options={clients}
            handleSelect={(value) => setFilters({ ...filters, client: value })}
          />
        </Col>
        <Col lg={2}>
          <SelectCustom
            placeholder={t('allYears')}
            label={t('years')}
            options={years}
            handleSelect={(value) => setFilters({ ...filters, year: value })}
          />
        </Col>
        <Col lg={2}>
          <SelectCustom
            placeholder={t('allMonths')}
            label={t('months')}
            options={months}
            handleSelect={(value) => setFilters({ ...filters, month: value })}
          />
        </Col>
        <Col lg={2}>
          <SelectCustom
            placeholder={t('allDevices', { ns: 'statistics' })}
            label={t('devices', { ns: 'statistics' })}
            options={devices}
            handleSelect={(value) => setFilters({ ...filters, type: value })}
          />
        </Col>
        <Col lg={3}>
          <SelectCustom
            placeholder={t('allProviders', {ns:'statistics'})}
            label={t('providers',{ns:'statistics'})}
            options={providers}
            handleSelect={(value) => setFilters({ ...filters, provider: value })}
          />
        </Col>

        <Col lg={12} className="mt-4 mb-2">
          <h3>{t('loansByDevice')}</h3>
        </Col>
        <Row>
          {statisticsData?.loan_by_device &&
            Object.keys(statisticsData.loan_by_device).map((key) => (
              <Col lg={2} md={3} className="mb-5" key={key}>
                <InfoCard title={key} data={statisticsData.loan_by_device[key]} />
              </Col>
            ))}
        </Row>
        {statisticsData?.loan_30_days_before && (
          <Col sm={12} lg={6}>
            <LineChart
              download={download30Days}
              enabledDownload={enabledDownload}
              labels={Object.keys(statisticsData?.loan_30_days_before).map((item) => item)}
              data={[
                {
                  label: t('loans'),
                  data: Object.keys(statisticsData?.loan_30_days_before).map(
                    (item) => statisticsData.loan_30_days_before[item],
                  ),
                  color: COLORS[0],
                },
              ]}
              title={
                t('loans30DaysBefore') +
                ' : ' +
                sumAllValues(statisticsData?.loan_30_days_before)
                  ?.toFixed(2)
                  ?.replace(/[.,]00$/, '')
              }
              loading={loading}
            />
          </Col>
        )}
        {statisticsData?.sum_30_days_before && (
          <Col sm={12} lg={6}>
            <LineChart
              download={downloadAmount30Days}
              enabledDownload={enabledDownload}
              labels={Object.keys(statisticsData?.sum_30_days_before).map((item) => item)}
              data={[
                {
                  label: t('amount'),
                  data: Object.keys(statisticsData?.sum_30_days_before).map(
                    (item) => statisticsData.sum_30_days_before[item],
                  ),
                  color: COLORS[3],
                },
              ]}
              title={
                t('amount30DaysBefore') +
                `(${COIN})` +
                ' : ' +
                sumAllValues(statisticsData?.sum_30_days_before)
                  .toFixed(2)
                  .replace(/[.,]00$/, '')
              }
              loading={loading}
            />
          </Col>
        )}
      </Row>
      <Row className="my-5">
        {statisticsData?.loan_by_year && (
          <Col md={6}>
            <BarChart
              data={[...Object.values(statisticsData.loan_by_year)]}
              label="loans"
              labels={[...Object.keys(statisticsData.loan_by_year)]}
              title={t('loansByYear') + ' : ' + statisticsData.loan_total}
              loading={loading}
            />
          </Col>
        )}

        {statisticsData?.loan_by_client && (
          <Col md={6}>
            <BarChart
              data={[...Object.values(statisticsData.loan_by_client)]}
              label="loans"
              labels={[...Object.keys(statisticsData.loan_by_client)]}
              title={t('loansByClients')}
              loading={loading}
            />
          </Col>
        )}

        {statisticsData?.loan_by_provider && (
          <Col md={12}>
            <BarChart
              data={[...Object.values(statisticsData.loan_by_provider)]}
              label="loans"
              labels={[...Object.keys(statisticsData.loan_by_provider)]}
              title={t('loansByProviders')}
              loading={loading}
            />
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default Statistics

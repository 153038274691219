import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";

const ModalConstructor = ({
  modalOpen,
  toggleModal,
  modalElements,
  onSubmit,
}) => {
  const [data, setData] = useState(null);

  const handleClick = (fnName) => {
    switch (fnName) {
      case "close":
        return toggleModal();

      default:
        break;
    }
  };

  const handleChangeData = (e, keyName) => {
    console.log(e, keyName);
    setData({ ...data, [keyName]: e.value });
  };

  const handleSubmit = () => {
    data && onSubmit(data);
  };

  const renderModalHeader = () => {
    return modalElements
      .filter((element) => element.section === "header")
      .map((element, idx) => {
        return (
          <div
            key={idx}
            style={{ textAlign: element.align }}
            className={`modal-header-${element.align}`}
          >
            {renderElement(element)}
          </div>
        );
      });
  };

  const renderModalBody = () => {
    return modalElements
      .filter((element) => element.section === "body")
      .map((element, idx) => {
        return (
          <div
            key={idx}
            style={{ textAlign: element.align }}
            className={`modal-body-${element.align}`}
          >
            {renderElement(element)}
          </div>
        );
      });
  };

  const renderModalFooter = () => {
    return modalElements
      .filter((element) => element.section === "footer")
      .map((element, idx) => {
        return (
          <div
            key={idx}
            style={{ textAlign: element.align }}
            className={`modal-footer-${element.align}`}
          >
            {renderElement(element)}
          </div>
        );
      });
  };

  useEffect(() => {
    console.log(modalElements);
  }, [modalElements]);

  const renderElement = (element) => {
    switch (element.type) {
      case "title":
        return <h3>{element.text}</h3>;
      case "subtitle":
        return <h5>{element.text}</h5>;
      case "button":
        return (
          <Button onClick={handleClick(element.fn)}>{element.text}</Button>
        );
      case "button-submit":
        return (
          <Button onClick={() => handleSubmit(data)}>{element.text}</Button>
        );
      case "button-close":
        return <Button onClick={() => toggleModal()}>{element.text}</Button>;
      case "input":
        return (
          <div>
            <label>{element.label}</label>
            <input
              name={element.name}
              type={element.inputType || "text"}
              defaultValue={element.defaultValue}
              onChange={(e) => handleChangeData(e.target, element.keyName)}
            />
          </div>
        );
      case "select":
        return (
          <div>
            <label>{element.label}</label>
            <select
              name={element.name}
              value={element.value}
              onChange={(e) => handleChangeData(e.target, element.keyName)}
            >
              {element.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        );
      case "textarea":
        return (
          <div>
            <label>{element.label}</label>
            <textarea
              name={element.name}
              value={element.value}
              onChange={(e) => handleChangeData(e.target, element.keyName)}
            />
          </div>
        );
      case "image":
        return <img src={element.src} alt={element.alt} />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader>{modalElements && renderModalHeader()}</ModalHeader>
      <ModalBody>
        <Col>
          <Row>{modalElements && renderModalBody()}</Row>
        </Col>
      </ModalBody>
      <ModalFooter>{modalElements && renderModalFooter()}</ModalFooter>
    </Modal>
  );
};

export default ModalConstructor;

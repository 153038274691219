import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../api/Api'

/**
 * Options for any fetch method
 * @typedef MethodOptions
 * @type {object}
 * @property {boolean} [skipLoading]
 * If `true`, that during fetching won't be changed the loading state.
 * By default - `false`
 * @property {boolean} [withRedirection]
 *  If `true`, in case of error will redirect to the error page (404/500/error).
 * By default - `false`
 */

/**
 * The own hook for fetching data. This speciment might be used for a project created by react.js.
 *
 * Under the hood uses a React state and useNavigate hook of 'react-router-dom'
 *
 * @returns  The object with methods (like methodGet or methodPost) and states (loading, error, etc...)
 */
function useFetch() {
  const [loading, setLoadingAPI] = useState(false)
  const [error, setErrorAPI] = useState(false)
  const [totalResults, setTotalResultsAPI] = useState(null)

  const navigate = useNavigate()

  /**
   * The function which uses axios for GET method
   * @async
   * @param {string!} url - url for method
   * @param {object|any|null} query - query for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodGet(url, query, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.get(url, { params: query })
      const { status, data } = response

      if (status === 200) {
        if (data.count || data.count === 0) setTotalResultsAPI(data.count)
        return response
      } else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
      return
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }
  /**
   * The function for POST method
   * @async
   * @param {string!} url - url for method
   * @param {object?} payload - object that contains data for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodPost(url, payload, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.post(url, JSON.stringify(payload))
      const { status, data } = response

      if (status === 201 || status === 200) {
        if (data.count || data.count === 0) setTotalResultsAPI(data.count)
        return response
      } else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
      return
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }

  /**
   * The function for POST FORM method
   * @async
   * @param {string!} url - url for method
   * @param {object?} form_data - object that contains form for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodPostForm(url, form_data, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.post(url, form_data)
      const { status, data } = response

      if (status === 201 || status === 200) {
        if (data.count || data.count === 0) setTotalResultsAPI(data.count)
        return response
      } else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
      return
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }
  /**
   * The function for DELETE method
   * @async
   * @param {string!} url - url for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodDelete(url, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.delete(url)
      const { status } = response
      if (status === 200 || status === 202 || status === 204) {
        return response
      } else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }

  /**
   * The function for PUT method
   * @async
   * @param {string!} url - url for method
   * @param {object?} payload - object that contains data for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodPut(url, payload, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.put(url, payload)
      const { status } = response
      if (status === 200 || status === 201 || status === 400) return response
      else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
      return
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }

  /**
   *
   * @param {string!} url - url for method
   * @param {object} [payload] - object that contains data for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodPatch(url, payload, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.patch(url, payload)
      const { status } = response
      if (status === 200 || status === 400) return response
      else if (options?.withRedirection) redirector(status)
      else setErrorAPI(status)
      return
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }

  /**
   *
   * @param {string!} url - url for method
   * @param {String} name - name for a new .xlsx document
   * @param {object} [payload] - object that contains data for method
   * @param {MethodOptions} [options] object with options for using method.
   * @returns {Promise<{status,data}>|undefined} When no errors, returns response object with status and data
   * @throws In case of error, does not return nothing.
   */
  async function methodExportExcel(url, name, payload, options) {
    resetState(options?.skipLoading)
    try {
      const response = await api.get(url, {
        responseType: 'blob',
        params: payload,
      })
      if (response?.status === 200 || response?.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}_${new Date().toISOString()}.xlsx`)
        document.body.appendChild(link)
        link.click()
        setErrorAPI(response.status)
      }
    } catch (error) {
      console.log(error)
      setErrorAPI(error)
    } finally {
      setLoadingAPI(false)
    }
  }

  function resetState(skipLoading) {
    if (!skipLoading) setLoadingAPI(true)
    if (error !== false) setErrorAPI(false)
    // if (totalResults) setTotalResultsAPI(null)
  }
  function redirector(status) {
    // if (status === 404) router.replace('/404')
    // else if (status === 500) router.replace('/500')
    // else
    navigate(`/error`, { state: status }) //.push(`/error?errorCode=${status}`)
  }

  return {
    loading,
    error,
    totalResults,
    methodGet,
    methodPost,
    methodDelete,
    methodPut,
    methodPatch,
    methodExportExcel,
    methodPostForm,
  }
}

export default useFetch

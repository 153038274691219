import React, { useState, useEffect } from 'react'
// import axios from "axios";
import { Row, Container, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// api
import useFetch from '../../../hooks/useFetch'
import { getGeneralInfo, getClients } from '../../../api/pathsForHook/administrator'
// own components
import InfoCard from '../../../components/cards/InfoCard'
import Table from '../../../components/table'
import Loading from '../../../components/Loading'

import { BiRefresh } from 'react-icons/bi'
// styles
import '../../../assets/css/charts.scss'

const Home = () => {
  const [generalInfo, setGeneralInfo] = useState([])
  const [tableClients, setTableClients] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation('homeAdmin')

  const { methodGet } = useFetch()

  useEffect(() => {
    getAllDataForPage()
  }, [])

  const getAllDataForPage = () => {
    setIsLoading(true)
    setGeneralInfo([])
    setTableClients([])
    setTimeout(() => {
      Promise.allSettled([fetchData(getGeneralInfo, setGeneralInfo), fetchData(getClients, setTableClients)]).then(
        setIsLoading(false),
      )
    }, 1000)
  }

  const fetchData = async (fetchFn, setDataFn) => {
    const { data } = await methodGet(fetchFn())
    setDataFn(data)
  }

  const refreshData = () => getAllDataForPage()

  const renderClientsTable = (data) => {
    return (
      <Col lg={12}>
        <Table header={[t('platform'), t('users'), t('loans'), t('budget'), t('remainingBudget')]}>
          {data?.map((item) => (
            <tr key={item.id}>
              <td>{item.platform_name}</td>
              <td>{item.count_users ? item.count_users : 0}</td>
              <td>{item.count_loans ? item.count_loans : 0}</td>
              <td className="text-right">{item.budget ? item.budget.toFixed(2) : 0} €</td>
              <td className="text-right">{item.budget ? (item.budget - item.amount_used).toFixed(2) : 0} €</td>
            </tr>
          ))}
        </Table>
      </Col>
    )
  }

  const renderCards = (data) => {
    return data?.map((item, i) => (
      <Col lg={3} md={4} sm={6} xs={12} key={i}>
        <InfoCard title={item.title} data={item.data} key={i} />
      </Col>
    ))
  }

  return (
    <Container className="d-flex justify-content-center flex-column">
      <Row className="mt-5 ">
        <Col lg={11}>
          <h1>{t('homeAdmin')}</h1>
        </Col>
        <Col lg={1}>
          <Button onClick={() => getAllDataForPage()} variant="success">
            <BiRefresh size="25" />
          </Button>
        </Col>
      </Row>
      {isLoading ? (
        <Loading />
      ) : (
        <Row className="my-5">
          {generalInfo && renderCards(generalInfo)}
          {tableClients && renderClientsTable(tableClients)}
        </Row>
      )}
    </Container>
  )
}

export default Home

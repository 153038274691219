// ========== GET METHODS ==========

export const getClientTiers = () => 'clients/client-tiers/'

export const getDataFilterClientTier = () => 'clients/client-tiers/data-filter/'

export const getClients = () =>  'clients/clients/get_client_web/' 

export const getAllClients = () => 'clients/clients/'

export const getPinnaInfo = (is_demo) => `statistics/pinna/clients/?is_demo=${is_demo}`

export const getClientsBudgets = () => 'clients/budgets'

// ========== POST METHODS ==========

export const activateAllItems =  () => '/clients/clients/enable-products-and-collections/'
import React from 'react'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { CustomButton } from '../../components/buttons'

const Page404 = () => {
  const navigate = useNavigate()

  const handleNavigate = (toPrevPage=false) => toPrevPage ? navigate(-1) : navigate("/",{replace:true})

  return (
    <Container className="mt-5 d-flex justify-content-center align-items-center flex-column">
        <h1>Page not found</h1>
        <div className='mt-3 d-flex'>
            <CustomButton onClick={handleNavigate} className="ms-3" variant="outline-primary" >Home page</CustomButton>
            <CustomButton onClick={() => handleNavigate(true)} className="ms-3" >Go back</CustomButton>
        </div>
    </Container>
  )
}


export default Page404
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Select from '../select'
import { CustomButton } from '../buttons'

//CSS
import '../../assets/css/styles.scss'

//translations
import { useTranslation } from 'react-i18next'

const SelectModal = ({ open, setModal, selectedDetail, options, sendGroupChange, label, type }) => {
  const { t } = useTranslation(['clientsGroups', 'common'])

  const [selectedGroup, setSelectedGroup] = useState(null)

  useEffect(() => {
    open && setSelectedGroup(0)
  }, [open])

  const saveClientsGroup = (group) => {
    const parsed = parseInt(group)
    setSelectedGroup(parsed)
  }

  const sendClientsGroup = () => {
    if (
      (type && selectedGroup !== selectedDetail?.slider_group?.id) ||
      (!type && selectedGroup !== selectedDetail?.list_group?.id)
    ) {
      sendGroupChange(selectedGroup)
    }
    setModal(false)
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={open}>
      <Modal.Header>
        <Modal.Title>{selectedDetail?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select key="select-group" options={options} handleSelect={saveClientsGroup} label={label} />
        {!options ? (
          <span className="error">{t('optionsError')}</span>
        ) : (
          selectedGroup === null || (selectedGroup === 0 && <span>{t('unassignGroup')}</span>)
        )}
      </Modal.Body>
      <Modal.Footer>
        <CustomButton className="online-csecondary" onClick={() => setModal(false)}>
          {t('cancel', { ns: 'common' })}
        </CustomButton>
        <CustomButton className="online-cprimary" onClick={sendClientsGroup}>
          {t('save', { ns: 'common' })}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectModal

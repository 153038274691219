// ========== GET METHODS ==========
export const getClientSliderGroup = () => 'products/client-slider-group/'

export const getGroupDetailsById = (id) => `administrator/slider-group/${id}/`

export const getSliderGroup = () => 'administrator/slider-group/'

export const getSliderById = (id) => `administrator/element-slider-group/${id}/`

// ========== POST METHODS ==========

export const createGroup = () => '/administrator/slider-group/'

export const createSlider = () => 'administrator/element-slider-group/'

export const addSliderToGroupSlider = (id, sliderId) => `administrator/slider-group/${id}/add-element/${sliderId}/`

export const assignClientSliderGroup = (id, clientId) => `administrator/slider-group/${id}/add-client/${clientId}/`

export const reorderSliderGroupElements = (id) => `administrator/slider-group/${id}/change-elements-order/`

// ========== DELETE METHODS ==========

export const deleteGroupSlidersById = (id) => `/administrator/slider-group/${id}/`

export const deleteSlider = () => 'administrator/element-slider-group/'

export const deleteElementSliderFromGroupSlider = (id, sliderId) =>
  `administrator/slider-group/${id}/remove-element/${sliderId}/`

export const removeClientSliderGroup = (id, clientId) => `administrator/slider-group/${id}/remove-client/${clientId}/`

// ========== PUT METHODS ==========

export const editSliderFromGroupSlider = (id) => `administrator/element-slider-group/${id}/`

export const changeClientSliderGroup = (id) => `administrator/slider-group/${id}/`

// ========== PATCH METHODS ==========

export const changeOnlyGroup = (id) => `administrator/slider-group/${id}/`

export const updateGroup = (id) => `administrator/slider-group/${id}/`

// ========== DELETE METHODS ==========

export const deleteSliderGroupApi = (id, clientId) => `administrator/slider-group/${id}/remove-client/${clientId}/`

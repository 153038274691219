import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
//api
import { getAllLists, editOrDeleteList } from '../../../api/pathsForHook/products'
// components
import { Container, Row, Col, Modal } from 'react-bootstrap'
import InputCustom from '../../../components/input'
import Table from '../../../components/table'
import { CustomButton } from '../../../components/buttons'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import Loading from '../../../components/Loading'
import Select from '../../../components/select'
// css
import '../../../assets/css/group_lists.scss'
//helpers
import { showToast } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'
import useSearch from '../../../hooks/useSearch'
//icons
import { RiDeleteBinLine } from 'react-icons/ri'
import { GoPencil } from 'react-icons/go'
//navigate
import { useNavigate, useLocation } from 'react-router-dom'
//context
import { AdminContext } from '../../../contexts/AdminContext'

const CollectionsManagement = () => {
  const { t } = useTranslation('management')
  const [links, setLinks] = useState({})
  const [lists, setLists] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [selectedList, setSelectedList] = useState({})
  const [order, setOrder] = useState('name')

  const { loading, totalResults, error, methodGet, methodDelete, methodPatch } = useFetch()

  const { adminState, setAdminState } = useContext(AdminContext)
  const location = useLocation()

  const orderBy = [
    {
      name: t('orderBy.alphabeticalAZ'),
      value: 'name',
    },
    {
      name: t('orderBy.alphabeticalZA'),
      value: '-name',
    },
    {
      name: t('orderBy.recent'),
      value: '-recent',
    },
    {
      name: t('orderBy.oldest'),
      value: 'recent',
    },
  ]

  useSearch({
    searchTerm: search,
    onProcessSearch() {
      ;(!adminState.automaticLists ||
        Object.keys(adminState.automaticLists).length === 0 ||
        (Object.keys(adminState.automaticLists).length > 0 && Object.keys(links).length > 0)) &&
        getListsByName(1)
    },
  })

  useEffect(() => {
    if (adminState.automaticLists) {
      if (Object.keys(adminState.automaticLists).length > 0) {
        let { results, links, currentPage } = adminState.automaticLists
        setLists(results)
        setLinks(links)
        setPage(currentPage)
      }
    }
  }, [adminState.automaticLists])

  useEffect(() => {
    location.state === 'detail' && getListsByName(1)
  }, [])

  useEffect(() => {
    getListsByName()
  }, [order])

  let navigate = useNavigate()

  const handleRoute = (id) => {
    id
      ? window.open(`/administrator/content-management/list/${id}`)
      : navigate(`/administrator/content-management/list/new-list`)
  }

  const handleShow = () => setShowModalDelete(true)

  const handleClose = () => {
    setShowModalDelete(false)
    setSelectedList(null)
  }

  const getListsByName = async (currentPage) => {
    const { data, status } = await methodGet(getAllLists(currentPage || page), {
      search: search,
      type: 2,
      skip_products: true,
      order_by: order,
    })

    if (data) {
      setLists(data.results)
      setLinks(data.links)
      setTotalPages(data.total_pages)
      data['currentPage'] = currentPage || page
      setAdminState({ ...adminState, automaticLists: data })
    } else if (status === 404 || status === 500) {
      showToast('info', t('serverError'))
    }
  }

  const deleteSelectedList = async (listSelected) => {
    const { status } = await methodDelete(editOrDeleteList(listSelected.id))
    if (status === 204) {
      const removeIndex = lists.findIndex((list) => list.id === listSelected.id)
      if (removeIndex !== -1) {
        const newLists = [...lists]
        newLists.splice(removeIndex, 1)
        setLists(newLists)
        setAdminState({
          ...adminState,
          automaticLists: {
            results: newLists,
            links,
            currentPage: page,
          },
        })
        showToast('success', t('deletedSuccessful', { ns: 'groupList' }))
      } else {
        showToast('info', t('serverError'))
      }
    } else {
      showToast('info', t('serverError'))
    }
    handleClose()
  }

  const changeList = async (item) => {
    const dataElements = item?.elements?.map(({ id, type, order, collections, products }) => ({
      element: parseInt(products.id) || parseInt(collections.id),
      type: parseInt(type),
      order: parseInt(order),
      section: collections?.type || products?.type,
    }))

    const payload = {
      name: item.name,
      active: !item.active,
      elements: dataElements,
    }

    const { status, data } = await methodPatch(editOrDeleteList(item.id), payload)

    if (status === 200 || status === 201) {
      const newLists = lists.map((singleList) => {
        if (singleList.id === item.id) {
          singleList.active = data.active
          return singleList
        } else {
          return singleList
        }
      })
      setLists(newLists)
      setAdminState({ ...adminState, automaticLists: { results: newLists, links, currentPage: page } })
      showToast('success', t('success', { ns: 'common' }))
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  const resetFilters = () => {
    setSearch('')
  }

  const handlePageChange = (e) => {
    setPage(e)
    getListsByName(e)
  }

  const openModalDelete = (group) => {
    handleShow()
    setSelectedList(group)
  }

  const renderTable = () => (
    <>
      <ButtonGoBack />
      {lists?.length ? (
        <Table
          header={t('headerTableListsManagement', {
            returnObjects: true,
          })}
          page={page}
          changePage={handlePageChange}
          next={links.next ? true : false}
          previous={links.previous ? true : false}
          totalPages={totalPages}
        >
          {lists?.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.content}</td>
              <td>
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  checked={item.active}
                  onChange={() => changeList(item)}
                />
              </td>
              <td className="d-flex justify-content-center actions-row-buttons">
                <span className="action-btn-edit " onClick={() => handleRoute(item.id)}>
                  <GoPencil className="icon icon-edit " />
                </span>

                <span className="action-btn-delete " onClick={() => openModalDelete(item)}>
                  <RiDeleteBinLine className="icon icon-delete" />
                </span>
              </td>
            </tr>
          ))}
        </Table>
      ) : null}
    </>
  )

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <Row className="mt-5">
        <h2>{t('listManagement')}</h2>
      </Row>
      <Row className="my-2">
        <Col lg={6} md={6} ms={12}>
          <InputCustom
            placeholder={t('searchByTitle')}
            className="border border-gray "
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Col>
        <Col lg={2} md={2} sm={8} xs={12}>
          <CustomButton className="online-cprimary mt-0 btn btn-outline-sucess w-100" onClick={() => handleRoute()}>
            {t('createList')}
          </CustomButton>
        </Col>
        <Col lg={2} md={2} xs={12} className="text-md-end">
          <CustomButton className="online-cprimary mt-0 btn btn-outline-sucess w-100" onClick={() => resetFilters()}>
            {t('reset', { ns: 'common' })}
          </CustomButton>
        </Col>
        <Col md={2}>
          <Select
            key="select-slider-type"
            options={orderBy}
            handleSelect={(value) => {
              setOrder(value)
            }}
            value={order}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {error ? (
            <div className="box-message-plug">
              <p>{t('errorDataNotFound', { ns: 'groupList' })}</p>
            </div>
          ) : totalResults === 0 && !loading ? (
            <Col md={12} className="box-message-plug">
              <p className="d-flex justify-content-center m-3">{t('noResults', { ns: 'common' })}</p>
            </Col>
          ) : loading && lists.length === 0 ? (
            <Loading />
          ) : (
            renderTable()
          )}
        </Col>
      </Row>
      <Modal show={showModalDelete} onHide={handleClose} centered>
        <Modal.Body>
          {t('delete', { ns: 'common' })} "{`${selectedList?.name}`}"?
        </Modal.Body>
        <Modal.Footer>
          <CustomButton onClick={() => deleteSelectedList(selectedList)} className=" mt-0 btn btn-outline-sucess ">
            {t('delete', { ns: 'common' })}
          </CustomButton>
          <CustomButton onClick={handleClose} className=" online-cprimary mt-0 btn btn-outline-sucess  ">
            {t('cancel', { ns: 'common' })}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default CollectionsManagement

import React, { useState, useEffect } from "react";

// api
import { getMonths } from "../../api/pathsForHook/provider";
import useFetch from "../../hooks/useFetch";
// OWN COMPONENTS
import Select from "../select";

// constans
import { MONTHS } from "../../constants";

const SelectMonths = (props) => {
  const [options, setOptions] = useState([
    { value: "", name: "-- Selecciona un mes --" },
  ]);

  const { methodGet } = useFetch();

  useEffect(() => {
    loadMonths();
  }, [props.year]);

  const loadMonths = async () => {
    const response = await methodGet(getMonths(), { year: props.year });
    if (response.status === 200) {
      let data = response.data.map((item) => ({
        value: item,
        name: MONTHS[item - 1],
      }));
      setOptions(data);
      props.handleSelect(data[0].value);
    }
  };

  return <Select options={options} handleSelect={props.handleSelect} />;
};

export default SelectMonths;

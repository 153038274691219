import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TableClients from "../../components/tableClients";
// apis
import {
  getClientTiers,
  getAllClients,
  getDataFilterClientTier,
} from "../../api/pathsForHook/clients";
// own hook
import useFetch from "../../hooks/useFetch";
// components
import Table from "../../components/table";
import Select from "../../components/select";
import { getDateFormat } from "../../helpers";
import "../../assets/css/styles.scss";
export const COIN = process.env.REACT_APP_COIN;

const ClientsProviderPage = (props) => {
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [clientTiers, setClientTiers] = useState(null);
  const { t } = useTranslation(["clients"]);

  const fakeData = [
    {
      platform_name: "Madrid",
      libraries: ["EDYE", "Pinna", "RTVE"],
      packs: ["Cine", "Series", "Artes Escenicas"],
      tier: "Tier 7",
      created: "2022-01-25T11:54:47.425Z",
      expired: "2023-12-25T11:54:47.425Z",
      consumption: {
        count: 10,
        amount: 100.4545,
      },
    },
    {
      platform_name: "Murcia",
      libraries: ["EDYE", "Pinna", "RTVE", "EDYE2", "Pinna2", "RTVE2"],
      packs: ["Cine", "Series Kids", "Games"],
      tier: "Tier 3",
      expired: "2023-10-25T11:54:47.425Z",
      consumption: {
        count: 10,
        amount: 90.5,
      },
    },
    {
      platform_name: "Asturias",
      libraries: ["RTVE", "EDYE", "Pinna"],
      packs: ["Audiolibros", "Series Infantiles", "Artes Escenicas", "Games"],
      tier: "Tier 5",
      created: "2022-02-25T11:54:47.425Z",
      consumption: {
        count: 10,
        amount: 87,
      },
    },
  ];

  const { methodGet } = useFetch();

  useEffect(() => {
    const loadClientTier = async () => {
      const response = await methodGet(getClientTiers());
      if (response) setClientTiers([...response.data]);
    };
    loadDataFilter();
    loadClientTier();
  }, []);

  useEffect(() => {
    loadDataFilter();
  }, []);

  const loadDataFilter = async () => {
    let payload = {};
    const response = await methodGet(getDataFilterClientTier(), payload);
    if (response) {
      setYears(
        years.length === 0
          ? [{ value: "", name: t("selectaYear") }, ...response.data.years]
          : years
      );
      setMonths([
        { value: "", name: t("selectaMonth") },
        ...response.data.months,
      ]);
    }
  };

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t("clients")}</h2>
      <Row>
        <Col>
          {/* <Table header={t("headerTableClients", { returnObjects: true })}>
            {clientTiers &&
              clientTiers.map((item, index) => (
                <tr key={index}>
                  <td>{item?.client?.platform_name}</td>
                  <td>{item?.tier?.number}</td>
                  <td>{item?.tier?.packs.join(", ")}</td>
                  <td>{getDateFormat(item?.created)}</td>
                  <td>{item?.tier?.zone}</td>
                  <td>
                    {item?.consumption?.count != 0 &&
                    item?.consumption?.amount != 0
                      ? item?.consumption?.amount + " " + COIN
                      : "Tarifa plana"}
                  </td>
                  <td>{item?.consumption?.count}</td>
                </tr>
              ))}
          </Table> */}

          {clientTiers && <TableClients data={clientTiers} />}
        </Col>
      </Row>
    </Container>
  );
};

export default ClientsProviderPage;

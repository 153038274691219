import React from "react";
import { Outlet } from "react-router-dom";

import Footer from "../components/footer";
import "../assets/css/layouts.scss";
import "../assets/css/styles.scss";

const SecondaryLayout = (props) => (
  <>
    <main className="bgd-primary center-vh-content height-inherit">
      <Outlet />
    </main>
    <Footer />
  </>
);

export default SecondaryLayout;

import { createContext } from "react";

const defaultUserContextValue = {
  userInfo: null,
  handleUserInfo: () => {
    //
  },
  isAuth: false,
  handleIsAuth: () => {
    //
  },
  hasUser: false,
};

const UserContext = createContext(defaultUserContextValue);

export default UserContext;

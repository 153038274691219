import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

//translations
//login
//en
import login_en from './translations/login/en/login.json'
//es
import login_es from './translations/login/es/login.json'

//admin
//en
import homeAdmin_en from './translations/admin/en/homeAdmin.json'
import groupList_en from './translations/admin/en/groupList.json'
import clientsGroups_en from './translations/admin/en/clientsGroups.json'
import management_en from './translations/admin/en/management.json'
//es
import homeAdmin_es from './translations/admin/es/homeAdmin.json'
import groupList_es from './translations/admin/es/groupList.json'
import clientsGroups_es from './translations/admin/es/clientsGroups.json'
import management_es from './translations/admin/es/management.json'

//provider
//en
import home_en from './translations/provider/en/home.json'
import clients_en from './translations/provider/en/clients.json'
import content_en from './translations/provider/en/content.json'
import header_en from './translations/provider/en/header.json'
import pinna_en from './translations/provider/en/pinna.json'
//es
import home_es from './translations/provider/es/home.json'
import clients_es from './translations/provider/es/clients.json'
import content_es from './translations/provider/es/content.json'
import header_es from './translations/provider/es/header.json'
import pinna_es from './translations/provider/es/pinna.json'

import { getValueStorage } from './helpers'

//commons
//en
import common_en from './translations/commons/en/common.json'
import exportCommon_en from './translations/commons/en/exports.json'
import formsCommon_en from './translations/commons/en/forms.json'
import statisticsCommon_en from './translations/commons/en/statistics.json'
//es
import common_es from './translations/commons/es/common.json'
import exportCommon_es from './translations/commons/es/exports.json'
import formsCommon_es from './translations/commons/es/forms.json'
import statisticsCommon_es from './translations/commons/es/statistics.json'

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: getValueStorage('selectLanguage'),
  debug: true,
  resources: {
    en: {
      //login
      login: login_en,
      //admin
      homeAdmin: homeAdmin_en,
      groupList: groupList_en,
      clientsGroups: clientsGroups_en,
      management: management_en,
      //provider
      home: home_en,
      header: header_en,
      content: content_en,
      clients: clients_en,
      pinna: pinna_en,
      //commons
      common: common_en,
      exports: exportCommon_en,
      forms: formsCommon_en,
      statistics: statisticsCommon_en,
    },
    es: {
      //login
      login: login_es,
      //admin
      homeAdmin: homeAdmin_es,
      groupList: groupList_es,
      clientsGroups: clientsGroups_es,
      management: management_es,
      //provider
      home: home_es,
      header: header_es,
      content: content_es,
      clients: clients_es,
      pinna: pinna_es,
      //commons
      common: common_es,
      exports: exportCommon_es,
      forms: formsCommon_es,
      statistics: statisticsCommon_es,
    },
  },
})

import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { RiDeleteBin5Fill, RiDeleteBinLine, RiImage2Fill, RiDragDropLine } from 'react-icons/ri'
import { FaGripVertical } from 'react-icons/fa'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'

const DragDropComponent = ({
  columns,
  onDragEnd,
  selectedProducts,
  sendSelectedProducts,
  changeStateVisibility,
  deleteSelection,
  listByCategoryCode,
  sliderMode,
}) => {
  const { t } = useTranslation('groupList')

  return columns ? (
    <div className="drag-and-drop-container">
      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns)}>
        {Object.entries(columns).map(([columnId, column], ind) => {
          return (
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
              key={columnId}
            >
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId} direction="vertical">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="wrapper_dnd_products"
                        style={{
                          background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                          padding: 4,
                          /*   display: "flex", */
                          overflowX: 'scroll',
                          minWidth: '700px',
                          maxWidth: '100%',
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      padding: 16,
                                      margin: '8px 8px',
                                      minHeight: '50px',
                                      backgroundColor: snapshot.isDragging ? '#26A8FF' : '#6EC2FD',
                                      color: 'white',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <span class="align-self-center">{item.name}</span>

                                    <div className="d-flex justify-content-end w-25">
                                      <span
                                        style={{
                                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                          borderRadius: '5%',
                                          padding: ' 10px',
                                        }}
                                      >
                                        {item.provider.name}
                                      </span>
                                      <RiDeleteBin5Fill
                                        onClick={() => {
                                          const newState = [...selectedProducts].filter((obj) => obj.id !== item.id)
                                          // newState.splice(index, 1);
                                          sendSelectedProducts(newState)
                                        }}
                                        className="icon-delete margin-left-5 align-self-center"
                                        style={{
                                          minWidth: '25%',
                                          maxWidth: '25%',
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </div>
            </div>
          )
        })}
      </DragDropContext>
    </div>
  ) : (
    <div className="drag-and-drop-container">
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result
          if (!destination) {
            return
          }
          if (source.index === destination.index) {
            return
          }
          onDragEnd(listByCategoryCode.items, source.index, destination.index)
        }}
      >
        <h3>
          {t('category')} : {listByCategoryCode.categoryCode}
        </h3>
        <Table striped={false}>
          <thead>
            <tr className="selected-list-table-row">
              {listByCategoryCode?.items?.length > 1 && (
                <th
                  style={{
                    minWidth: '3rem',
                    maxWidth: '3rem',
                  }}
                >
                  {/*   {t('order')} */} <RiDragDropLine />
                </th>
              )}
              {sliderMode && <th>Slider</th>}
              <th className="selected-list-row-name">{t('name')}</th>
              <th
                style={{
                  minWidth: '5vw',
                  maxWidth: '5vw',
                }}
                className="text-align-center"
              >
                {t('visible')}
              </th>
              <th
                style={{
                  minWidth: '5vw',
                  maxWidth: '5vw',
                }}
              ></th>
            </tr>
          </thead>
          <Droppable droppableId={'listByCategoryCode' + listByCategoryCode.categoryCode}>
            {(droppableProvided) => (
              <tbody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {listByCategoryCode?.items?.length > 0 &&
                  listByCategoryCode?.items.map((list, index) => (
                    <Draggable key={list.id} draggableId={list.id + 'draggableId'} index={index}>
                      {(draggableProvided) => (
                        <tr
                          className="selected-list-table-row"
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                        >
                          {listByCategoryCode?.items?.length > 1 && (
                            <td
                              {...draggableProvided.dragHandleProps}
                              style={{
                                minWidth: '3rem',
                                maxWidth: '3rem',
                              }}
                            >
                              <FaGripVertical size="20" />
                            </td>
                          )}
                          {sliderMode && (
                            <td>
                              {<img className="thumbnail" src={list.cover_slider_info.cover} /> || <RiImage2Fill />}
                            </td>
                          )}

                          <td className="selected-list-row-name">
                            {list.list_name ||
                              list.cover_slider_info.collection?.name ||
                              list.cover_slider_info.product?.name ||
                              t('undefinedName')}
                          </td>
                          <td
                            style={{
                              minWidth: '5vw',
                              maxWidth: '5vw',
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ cursor: 'pointer' }}
                              value={list.visible || list.active}
                              checked={list.visible || list.active}
                              onClick={() => changeStateVisibility(list)}
                              onChange={() => changeStateVisibility(list)}
                            />
                          </td>
                          <td
                            style={{
                              minWidth: '5vw',
                              maxWidth: '5vw',
                              cursor: 'pointer',
                            }}
                            className="d-flex justify-content-center actions-row-buttons"
                          >
                            <span onClick={() => deleteSelection(list.id)} className="action-btn-delete">
                              <RiDeleteBinLine />
                            </span>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                {droppableProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </div>
  )
}
export default DragDropComponent

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Col, Row, Container, Spinner, Button } from 'react-bootstrap'
import { TiDownloadOutline } from 'react-icons/ti'

// api paths
import { getLoansByMonths, getLoanLibraries, getLoans, getSummary } from '../../../api/pathsForHook/provider'
// own hooks
import useFetch from '../../../hooks/useFetch'
// constans
import { MONTHS, API_EFILM } from '../../../constants'

// own components
import BarChart from '../../../components/charts/BarChart'
import PolarChart from '../../../components/charts/PolarChart'
import SelectMonth from '../../../components/filters/SelectMonths'
import SelectYear from '../../../components/filters/SelectYear'
import Table from '../../../components/table'
import { CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
import { BiRefresh } from 'react-icons/bi'

//translations
import { useTranslation } from 'react-i18next'

const Home = () => {
  const [loansMonth, setLoansMonth] = useState([])
  const [loans, setLoans] = useState()
  const [loanLibraries, setLoanLibraries] = useState(null)
  const [summary, setSummary] = useState(null)
  const [yearTotal, setYearTotal] = useState(0)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [page, setPage] = useState(1)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation(['home', 'contenido'])

  const { error, methodGet } = useFetch()

  useEffect(() => {
    loadAllDataOfPage()
  }, [])

  const loadAllDataOfPage = () => {
    setLoading(true)
    setTimeout(() => {
      Promise.allSettled([fetchData(getLoanLibraries, setLoanLibraries), fetchData(getSummary, setSummary)]).then(
        setLoading(false),
      )
    }, 1000)
  }

  const fetchData = async (fetchFn, setDataFn) => {
    const { data } = await methodGet(fetchFn())
    setDataFn(data)
  }

  useEffect(() => {
    handleLoansByMonth()
  }, [year])

  useEffect(() => {
    handleLoans()
  }, [month, year, page])

  useEffect(() => {
    setPage(1)
  }, [month, year])

  useEffect(() => {
    setLoading(loansMonth?.length === 0)
  }, [loansMonth])

  // const loadLoanLibraries = async () => {
  //   const response = await methodGet(getLoanLibraries())
  //   if (response) setLoanLibraries(response.data)
  // }

  // const loadSummary = async () => {
  //   const response = await methodGet(getSummary())
  //   if (response) setSummary(response.data)
  // }

  const serializerLoansByMonth = (data) => {
    setYearTotal(0)
    return data.map((item) => {
      setYearTotal((prev) => prev + item[1])
      return { mes: MONTHS[item[0] - 1], prestamos: item[1] }
    })
  }

  const handleLoansByMonth = async () => {
    setLoading(true)
    if (year !== '') {
      const response = await methodGet(getLoansByMonths(), { year: year })
      if (response) {
        setLoading(false)
        setLoansMonth(serializerLoansByMonth(response.data))
      }
    }
  }

  const handleLoans = async () => {
    if (year !== '' && month !== '') {
      const response = await methodGet(getLoans(), { year, month, page })
      if (response) setLoans(response.data)
    }
  }

  const exportLoans = async () => {
    setDisabledBtn(true)
    axios({
      url: API_EFILM + 'statistics/provider/loan-export/?year=' + year + '&month=' + month,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: 'JWT ' + localStorage.getItem('tokenAuth'),
        'Accept-Language': i18n.language,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'loans.xls')
      document.body.appendChild(link)
      link.click()
      setDisabledBtn(false)
    })
  }

  return (
    <Container className="d-flex justify-content-center flex-column">
      <Row className="mt-5 ">
        <Col lg={11}>
          <h1>Home Provider</h1>
        </Col>
        <Col lg={1}>
          <Button onClick={() => loadAllDataOfPage()} variant="success">
            <BiRefresh size="25" />
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className="pt-5">
            <Col md={4}>
              {summary && (
                <div>
                  <h2>{t('totals', { ns: 'content' })}</h2>
                  <div className="row">
                    <div className="col badge-info">{summary.total} Un.</div>
                    <div className="col badge-info">
                      {summary.amount_total} {process.env.REACT_APP_COIN}
                    </div>
                  </div>
                </div>
              )}
              {loanLibraries && (
                <div>
                  <h2>{t('amountByClient')}</h2>
                  {loanLibraries[0].map((item, index) => (
                    <div className="card-info" key={index}>
                      <span className="title">{item.toUpperCase()}</span>
                      <p className="info">{`${loanLibraries[2][index]} ${process.env.REACT_APP_COIN}`}</p>
                    </div>
                  ))}
                </div>
              )}
            </Col>
            <Col md={8} className="section-polar-chart d-flex justify-content-center flex-column">
              <h2 className="text-center">{t('numberLoansByClient')}</h2>
              <PolarChart
                title="Consumo por cliente"
                labels={loanLibraries ? loanLibraries[0] : []}
                data={loanLibraries ? loanLibraries[1] : [3, 2, 5, 43, 54, 24, 75, 2]}
              />
            </Col>
          </Row>
          <Row className=" mt-lg-3 pt-lg-3 mt-md-3 pt-md-3 mt-sm-3 ">
            <Col md={6} className="p-xs-5">
              <SelectYear handleSelect={setYear} />
            </Col>
            <Col md={6}>
              <h4 className="mt-sm-2 mt-2">
                {t('loansYear')} {yearTotal}
              </h4>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="section-bar-chart">
              <BarChart
                data={loansMonth?.map((item) => item.prestamos)}
                primary_key="mes"
                loading={loading}
                labels={loansMonth?.map((item) => item.mes)}
                datakeys={[{ key: t('loans').toLowerCase(), color: '#faaa00' }]}
              />
            </Col>
          </Row>
          <Row className="mt-4 pt-4  ">
            <Col md={6} className="pb-sm-3">
              <SelectMonth year={year} handleSelect={setMonth} />
            </Col>
            <Col md={4}>
              <CustomButton disabled={disabledBtn} className="online-cprimary" onClick={exportLoans}>
                {t('export')}{' '}
                {disabledBtn ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <TiDownloadOutline />
                )}
              </CustomButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                header={t('headerTableHome', { returnObjects: true })}
                page={page}
                changePage={setPage}
                next={loans ? (loans.links.next ? true : false) : false}
                previous={loans ? (loans.links.previous ? true : false) : false}
              >
                {loans
                  ? loans.results.map((item, i) => (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.created}</td>
                      </tr>
                    ))
                  : null}
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default Home

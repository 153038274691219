import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  Legend,
  PointElement,
  LineElement,
  RadialLinearScale,
} from "chart.js";
import "./index.css";
import "./assets/css/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n-config";

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  Legend,
  PointElement,
  LineElement,
  RadialLinearScale
);

const container = document.getElementById("root");

createRoot(container).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

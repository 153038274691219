import PropTypes from "prop-types";
import React from "react";

import { Form } from "react-bootstrap";

const InputCustom = (props) => {
  return (
    <Form.Group>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Form.Control
        className="input-text"
        {...props}
        type={props.type}
        placeholder={props.placeholder}
      />
      {props.error && props.error !== "" && (
        <Form.Control.Feedback style={{ display: "block" }} type="invalid">
          {props.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

InputCustom.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

export default InputCustom;

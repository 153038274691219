import React, { useState, useEffect, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
//Hook
import useFetch from '../../../hooks/useFetch'
// components
import ExportCard from '../../../components/cards/ExportCard'
import { CustomButton } from '../../../components/buttons'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
// api
import { activateAllItems } from '../../../api/pathsForHook/clients'
// context
import { GeneralContext } from '../../../contexts/GeneralContext'
// helpers
import { showToast } from '../../../helpers'

const ActivateAll = () => {
  const { t } = useTranslation('management')
  const { clients } = useContext(GeneralContext)
  const [selectedClient, setSelectedClient] = useState([])

  const { methodPost } = useFetch()

  const handleActivateItems = async () => {
    const payload = {
      client: selectedClient,
      update_all: true,
    }
    const response = await methodPost(activateAllItems(), payload)
    if (response?.status === 200 || response?.status === 201) {
      showToast('success', t('success', { ns: 'common' }))
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('activateAll')}</h2>
      <Row>
        <Col
          lg={6}
          md={6}
          xs={12}
          className="pt-4 w-50d-flex m-auto justify-content-center align-items-center  flex-column"
        >
          <h3 className="mt-2 mb-3">
            {selectedClient.length === 0 || selectedClient === 'default' ? t('selectClient') : t('selectedClient')} :
          </h3>
          <Form.Select
            size="lg"
            aria-label="Default select example"
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            <option selected value="default">
              {t('selectClient')}
            </option>
            {clients.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className="m-auto" lg={2} md={2} xs={12}>
          <CustomButton onClick={() => handleActivateItems()} className="mt-5 fs-4 online-cprimary">
            {t('activate')}
          </CustomButton>
        </Col>
      </Row>
    </Container>
  )
}

export default ActivateAll

import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
//api
import { changeCollections, getCollections } from '../../../api/pathsForHook/collections'
import { getProvidersBySite, updateCollectionsMassively } from '../../../api/pathsForHook/products'
// components
import { Container, Row, Col } from 'react-bootstrap'
import InputCustom from '../../../components/input'
import Table from '../../../components/table'
import { CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
import Select from '../../../components/select'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
// css
import '../../../assets/css/group_lists.scss'
// helpers
import { showToast } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'
//context
import { AdminContext } from '../../../contexts/AdminContext'

const CollectionsManagement = () => {
  const { t } = useTranslation('management')
  const [links, setLinks] = useState({})
  const [collections, setCollections] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const [collectionType, setCollectionType] = useState('')
  const [collectionProviders, setCollectionProviders] = useState([])
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')

  const { loading, totalResults, error, methodGet, methodPost, methodPatch } = useFetch()
  const { adminState, setAdminState } = useContext(AdminContext)

  useEffect(() => {
    if (Object.keys(adminState?.collections).length === 0) {
      getListCollections()
    } else {
      setCollections(adminState.collections.results)
      setLinks(adminState.collections.links)
      setPage(adminState.collections.currentPage)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(adminState.collections).length > 0 && Object.keys(links).length > 0) {
      const timeout = setTimeout(() => {
        getListCollections(1)
        setPage(1)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [search, collectionType])

  useEffect(() => {
    if (collectionProviders.length === 0) {
      getProviders()
    }
  }, [])

  const getProviders = async () => {
    const { status, data } = await methodGet(getProvidersBySite())
    if (status === 200 || status === 201) {
      const results = data?.filter((item) => item?.content_type?.includes('serie'))
      setCollectionProviders(results)
      return results
    }
  }

  const updateCollections = async (filterType, statusChange) => {
    let payload = { ids: selectedItems }
    if (filterType === 'activate') {
      payload = { active: true }
    } else if (filterType === 'status_global') {
      payload = { status_global: statusChange }
      setSelectedStatus(statusChange)
    }
    const { status, data } = await methodPost(updateCollectionsMassively(), payload)
    if (status === 200 || status === 201) {
      setSelectedItems([])
      setSelectAll(false)
      getListCollections()
      showToast('success', t('success', { ns: 'common' }))
    } else {
      showToast('error', t('error', { ns: 'common' }))
    }
  }

  const selectAllItems = () => {
    const allItemsSelected = selectedItems.length === collections.length
    if (allItemsSelected) {
      setSelectedItems([])
    } else {
      const allItemIds = collections.map((item) => item.id)
      setSelectedItems(allItemIds)
    }
    setSelectAll(!selectAll)
  }

  const toggleItemSelection = (item) => {
    const isSelected = selectedItems.includes(item.id)
    if (isSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== item.id))
    } else {
      setSelectedItems([...selectedItems, item.id])
    }
  }

  const getListCollections = async (currentPage) => {
    const payload = {}
    if (search !== '' && search !== 'initial') {
      payload.name = search
    }
    if (collectionType !== '' && collectionType !== 'initial') {
      payload.type = collectionType
    }

    if (selectedProvider !== 'filterBy') {
      payload.provider_pk = selectedProvider
    }

    const { data, status } = await methodGet(getCollections(currentPage || page), payload)

    if (status === 200 || status === 201) {
      setCollections(data.results)
      setAdminState({
        ...adminState,
        collections: {
          results: data.results,
          links: data.links,
          currentPage: currentPage || page,
        },
      })
      setLinks(data.links)

      setTotalPages(data.total_pages)
    }
  }

  const changeListCollections = async (item, event, type) => {
    const payload = {
      name: item.name,
      [type === 'status' ? 'status_global' : 'active']: type === 'status' ? event : !item.active,
    }

    try {
      const { status, data } = await methodPatch(changeCollections(item.id), payload)
      if (status === 200 || status === 201) {
        const newCollections = collections.map((collection) => (collection.id === item.id ? data : collection))

        setCollections(newCollections)
        setAdminState({ ...adminState, collections: { results: newCollections, links, currentPage: page } })
        showToast('success', t('success', { ns: 'common' }))
      } else {
        showToast('error', t('error', { ns: 'common' }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getStatusOptions = () => {
    return [
      { name: t('comingSoon'), value: '2' },
      { name: t('available'), value: '1' },
    ]
  }

  const getTypeOptions = () => {
    return [
      { name: t('forms.filterByType', { ns: 'forms' }), value: '' },
      { name: t('series'), value: '1' },
      { name: t('magazine'), value: '3' },
    ]
  }

  const resetFilters = () => {
    setSearch('')
    setCollectionType('')
    setSelectedProvider('filterBy')
    setSelectAll(false)
    selectAllItems([])
  }

  useEffect(() => {
    getListCollections(1)
  }, [selectedProvider])

  const handlePageChange = (e) => {
    setPage(e)
    getListCollections(e)
  }

  const renderTable = () => (
    <>
      {collections?.length ? (
        <Table
          header={t('headerTableCollectionsManagement', {
            returnObjects: true,
          })}
          page={page}
          changePage={handlePageChange}
          next={links.next ? true : false}
          previous={links.previous ? true : false}
          totalPages={totalPages}
        >
          {collections?.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  onChange={() => toggleItemSelection(item)}
                  checked={selectedItems.includes(item.id)}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.type}</td>
              <td>
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  checked={item.active}
                  onChange={() => changeListCollections(item)}
                />
              </td>
              <td>
                <Select
                  key="select-status"
                  options={getStatusOptions()}
                  handleSelect={(e) => changeListCollections(item, e, 'status')}
                  defaultValue={item.status_global}
                />
              </td>
            </tr>
          ))}
        </Table>
      ) : null}
    </>
  )

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Row className="mt-5">
        <h2>{t('collectionManagement')}</h2>
      </Row>
      <Row className="my-2 w-100 d-flex justify-content-between">
        <Col lg={5} md={6} sm={8} xs={12}>
          <InputCustom
            placeholder={t('searchByTitle')}
            className="border border-gray "
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Col>
        <Col className="text-md-end">
          <Select
            key="select-language"
            options={getTypeOptions()}
            handleSelect={setCollectionType}
            value={collectionType}
          />
        </Col>
        <Col>
          <Select
            key="select-provider"
            value={selectedProvider}
            options={collectionProviders}
            defaultOption={{ name: t('forms.filterByProvider', { ns: 'forms' }), value: 'filterBy' }}
            handleSelect={setSelectedProvider}
          />
        </Col>
        <Col>
          <CustomButton onClick={() => selectAllItems()}>
            {selectAllItems.length > 0 ? t('unSelectAll') : t('selectAll')}
          </CustomButton>
        </Col>
        <Col lg={2} md={2} xs={12} className="text-md-end">
          <CustomButton className="online-cprimary mt-0 me-2 btn btn-outline-sucess " onClick={() => resetFilters()}>
            {t('reset', { ns: 'common' })}
          </CustomButton>
        </Col>
        <Col lg={5} md={2} xs={12} className="text-md-end"></Col>
      </Row>
      {selectAll && (
        <Row className="my-2 w-100 d-flex justify-content-between">
          <Col>
            <CustomButton onClick={() => updateCollections('activate')}>{t('activate')}</CustomButton>
          </Col>
          <Col>
            <Select
              key="select-status"
              options={getStatusOptions()}
              defaultOption={{ name: 'Cambia el estado', value: 'default' }}
              disabled={true}
              handleSelect={(selectedOption) => {
                updateCollections('status_global', selectedOption)
              }}
              value={selectedStatus}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {error ? (
            <div className="box-message-plug">
              <p>{t('errorDataNotFound', { ns: 'groupList' })}</p>
            </div>
          ) : totalResults === 0 && !loading ? (
            <Col md={12} className="box-message-plug">
              <p className="d-flex justify-content-center m-3">{t('noResults', { ns: 'common' })}</p>
            </Col>
          ) : loading && collections.length === 0 ? (
            <Loading />
          ) : (
            renderTable()
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default CollectionsManagement

import PropTypes from 'prop-types'
import { Button, Col, Row } from 'react-bootstrap'
import { SiMicrosoftexcel } from 'react-icons/si'

const ChartContainer = ({
  loading = false,
  className = '',
  isCircular = false,
  title,
  children,
  download,
  enabledDownload,
  ...props
}) => {
  const classNames = className + (isCircular && loading ? ' border-100' : '')
  return (
    <>
      <Row>
        <Col xs={9}>{title && <span className="chart__title">{title}</span>}</Col>
        <Col xs={3} className="text-right ">
          {download && (
            <Button
              title="Descargar excel"
              className="mt-2"
              disabled={!enabledDownload}
              variant="success"
              onClick={download}
            >
              <SiMicrosoftexcel color="white" size={23} />
            </Button>
          )}
        </Col>
      </Row>
      <div className={classNames}>
        {/* <ChartSkeleton loading={loading}/> */}
        {children}
      </div>
    </>
  )
}

ChartContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  download: PropTypes.func,
  isCircular: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default ChartContainer

import ReactLoading from "react-loading";

const Loading = (props) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 300 }}
    >
      <ReactLoading
        type="bubbles"
        className="loading"
        color="#e46b60"
        width={100}
        height={100}
      />
    </div>
  );
};

export default Loading;

import React, { useState, useEffect } from 'react'
//api
import {
  createGroup,
  deleteElementSliderFromGroupSlider,
  reorderSliderGroupElements,
  editSliderFromGroupSlider,
  getGroupDetailsById,
  updateGroup,
} from '../../../api/pathsForHook/sliders'
// components
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import InputCustom from '../../../components/input'
import { ComboButtons, CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
import ButtonGoBack from '../../../components/buttons/GoBackButton'
import ModalConstructor from '../../../components/modals/ModalConstructor'
import DragDropComponent from '../../../components/dragDrop'
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb'

// router
import { useParams, useNavigate, Link } from 'react-router-dom'
//icons
import { RiAddFill } from 'react-icons/ri'

// helpers
import { showToast } from '../../../helpers'
import { useTranslation } from 'react-i18next'
// css
import '../../../assets/css/group_lists.scss'
// own hooks
import useFetch from '../../../hooks/useFetch'

const SliderGroupDetails = () => {
  let { id } = useParams()

  const [groupDetails, setGroupDetails] = useState(null)
  const [isChangingStateOfSlider, setIsChangingStateOfSlider] = useState(false)
  const [isStatus, setIsStatus] = useState(null)
  const [inputNameValue, setInputNameValue] = useState('')
  const [sliders, setSliders] = useState([])
  const [selectedSliders, setSelectedSliders] = useState([])
  const [groupId, setGroupId] = useState('')

  // separation of sliders by category
  const [sortedSlidersByCategoryCode, setSortedSlidersByCategoryCode] = useState(null)

  // modal edit list
  const [modalOpen, setModalOpen] = useState(false)
  const [sliderToEdit, setSliderToEdit] = useState(null)
  const [modalEditSliderElements, setModalEditSliderElements] = useState(null)

  useEffect(() => {
    if (id && id !== 'new') {
      setGroupId(id)
      getGroupDetails(id)
    }
  }, [id])

  const toggleModal = () => setModalOpen(!modalOpen)

  let navigate = useNavigate()
  const { t } = useTranslation('groupList')

  const { loading, methodGet, methodPost, methodDelete, methodPut, methodPatch } = useFetch()

  const getGroupDetails = async (id) => {
    const response = await methodGet(getGroupDetailsById(id))
    if (response) {
      const { status, data } = response
      setIsStatus(status)
      if (data) {
        const slidersSortedByOrder = data.elements.sort((a, b) => a.order - b.order)
        setGroupDetails(data)
        setInputNameValue(data.name)
        setSelectedSliders(slidersSortedByOrder)
        setIsStatus(200)
        setIsChangingStateOfSlider(false)
      }
    } else {
      setIsStatus(500)
    }
  }

  const createNewGroup = async () => {
    const payload = {
      name: inputNameValue,
    }
    const response = await methodPost(createGroup(), payload)
    if (response) {
      showToast('success', t('groupCreatedSuccessful'))
      navigate(`/administrator/slider-group-details/${response.data.id}`)
    }
  }

  const saveChanges = async () => {
    const payload = {
      ...groupDetails,
      name: inputNameValue,
    }

    const response = await methodPatch(updateGroup(id), payload)
    if (response) {
      showToast('success', t('changesSaved'))
      navigate('/administrator/group-sliders')
    }
  }

  const deleteSelectedSlider = async (slider_id) => {
    if (isChangingStateOfSlider) {
      return
    }
    setIsChangingStateOfSlider(true)
    try {
      const response = await methodDelete(deleteElementSliderFromGroupSlider(id, slider_id))
      setSelectedSliders(selectedSliders.filter((el) => el.id !== slider_id))
      showToast('success', t('deletedSuccessful'))
    } catch (error) {
      console.log(error)
      showToast('error', t('deletionFailed'))
    } finally {
      setIsChangingStateOfSlider(false)
    }
  }

  const editSlider = async (slider_id, payload) => {
    const response = await methodPut(editSliderFromGroupSlider(slider_id), payload, {
      skipLoading: true,
    })
    !response && showToast('error', t('error', { ns: 'common' }))
  }

  const changeStateVisibilityOfSlider = async (slider) => {
    if (!isChangingStateOfSlider) {
      setIsChangingStateOfSlider(true)

      let payload = {
        slider_group: id,
        active: !slider.active,
        slider: slider,
      }

      await editSlider(slider.id, payload)
      await getGroupDetails(id)
      setIsChangingStateOfSlider(false)
    }
  }

  const reOrder = async (slider, startIndex, endIndex) => {
    setIsChangingStateOfSlider(true)
    const category = slider[0].category
    const result = [...slider]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    const updatedSlider = result.map((slider, index) => ({
      ...slider,
      order: index + 1,
    }))
    const orderList = updatedSlider.map(slider => slider.id)

    const payload = {
      order: orderList,
      category: category
    }

    try {
      await methodPost(reorderSliderGroupElements(id), payload)
    } catch (error) {
      console.log(error)
      showToast('error', t('postFailed'))
    }
    
    getGroupDetails(id)
  }

  const handleSubmit = (data) => {
    let payload = { slider_group: id, list: sliderToEdit.slider, ...data }
    editSlider(sliderToEdit.id, payload)
  }

  const handleClose = () => {
    setModalEditSliderElements(null)
    setSliderToEdit(null)
  }

  const filterAndGroupByCategory = (items) => {
    const uniqueCategoryCodes = [...new Set(items.map((item) => item.slider_category.code))]
    const groupedItemsByCategory = uniqueCategoryCodes.map((categoryCode) => ({
      categoryCode,
      items: items.filter((item) => item.slider_category.code === categoryCode),
    }))
    return groupedItemsByCategory
  }

  const displaySliderByCategoryCode = (sliderByCategoryCode, idx) => {
    return (
      <Col
        className="table-selected-lists"
        style={{
          marginTop: '2rem',
          padding: '1rem',
          border: '2px solid #d4d4d4',
        }}
        lg={{ span: 5, offset: idx % 2 === 0 ? 1 : 0 }}
      >
        <DragDropComponent
          onDragEnd={reOrder}
          listByCategoryCode={sliderByCategoryCode}
          changeStateVisibility={(slider) => changeStateVisibilityOfSlider(slider)}
          deleteSelection={(id) => deleteSelectedSlider(id)}
          sliderMode={true}
        />
      </Col>
    )
  }

  useEffect(() => {
    modalOpen === false && handleClose()
  }, [modalOpen])

  useEffect(() => {
    if (selectedSliders.length) {
      let filteredSliders = filterAndGroupByCategory(selectedSliders)
      setSortedSlidersByCategoryCode(filteredSliders)
    } else {
      setSortedSlidersByCategoryCode(null)
    }
  }, [selectedSliders])

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Breadcrumb
        route={'/administrator/group-sliders'}
        prevPageName={t('groupsOfSliders')}
        activeItemName={id === 'new' ? t('createNewGroup') : groupDetails?.name ? `${groupDetails.name}` : `#${id}`}
      />
      <Row>
        <Col>
          {isStatus === 404 && <p>{t('errorNoData')}</p>}
          {isStatus === 500 && <p>{t('errorServer')}</p>}
          {loading ? (
            <Loading />
          ) : (
            <>
              <Row className=" w-100 d-flex">
                <Col lg={12}>
                  <h3>{t('name')}</h3>
                </Col>
                <Col lg={12}>
                  <InputCustom
                    placeholder={t('nameOfGroup')}
                    className="border border-gray "
                    value={inputNameValue}
                    onChange={(e) => {
                      setInputNameValue(e.target.value)
                    }}
                  />
                </Col>
              </Row>
              {id !== 'new' && (
                <>
                  <div className="up-down-margin">
                    <Link to={`/administrator/slider-group-details/${groupId}/new-slider`}>
                      <button className="online-cprimary mt-0 btn btn-outline-sucess ">{t('createNewSlider')}</button>
                    </Link>
                  </div>
                  <Row className=" w-100 d-flex">
                    {isChangingStateOfSlider ? (
                      <Col lg={12} className="box-message-plug">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">{t('loading')}</span>
                        </Spinner>
                      </Col>
                    ) : sortedSlidersByCategoryCode?.length ? (
                      <Row>
                        {sortedSlidersByCategoryCode
                          .sort((a, b) => a.categoryCode.localeCompare(b.categoryCode))
                          .map((slider, idx) => displaySliderByCategoryCode(slider, idx + 1))}
                      </Row>
                    ) : (
                      <Col className="box-message-plug" lg={12}>
                        <span>{t('noCreatedSliders')}</span>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <ComboButtons
                cancelRoute={'/administrator/group-sliders'}
                secondaryText={t('back')}
                primaryText={id === 'new' ? t('create') : t('save')}
                handleClick={id === 'new' ? () => createNewGroup() : () => saveChanges()}
              />
            </>
          )}
        </Col>
      </Row>
      <ModalConstructor
        modalOpen={modalOpen && modalEditSliderElements ? true : false}
        toggleModal={() => toggleModal()}
        modalElements={modalEditSliderElements}
        onSubmit={(data) => handleSubmit(data)}
      />
    </Container>
  )
}

export default SliderGroupDetails

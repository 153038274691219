export const API_EFILM = process.env.REACT_APP_API_EFILM

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const COLORS = [
  '#CA715C',
  '#E19A80',
  '#D5A758',
  '#4C7E84',
  '#80A4BB',
  '#CBCDCE',
  '#8F839F',
  '#473E50',
  '#8F4632',
  '#473E50',
  '#F9F8F7',
  '#FFE5D9',
  '#BEC23F',
  '#D6C3A9',
  '#F0A07C',
  '#6E7172',
  '#E4BFC3',
  '#2C2D2F',
  '#F5DD90',
  '#9CDBD9',
  '#CCBAC7',
]

export const LANGUAGES = { es: 'es', en: 'en' }

export const clientName = process.env.REACT_APP_CLIENT_NAME

export const link = process.env.REACT_APP_CLIENT_LINK

export const logoShelf = process.env.REACT_APP_CLIENT_LOGO

export const COIN = process.env.REACT_APP_COIN

import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
//api
import { getSliderGroup, deleteGroupSlidersById } from '../../../api/pathsForHook/sliders'
// components
import InputCustom from '../../../components/input'
import Table from '../../../components/table'
import { CustomButton } from '../../../components/buttons'
import Loading from '../../../components/Loading'
// icons
import { GoPencil } from 'react-icons/go'
import { RiDeleteBinLine } from 'react-icons/ri'
// router
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// css
import '../../../assets/css/group_lists.scss'
import { showToast } from '../../../helpers'
// own hooks
import useFetch from '../../../hooks/useFetch'

const GroupSliders = () => {
  const [grouplists, setGroupLists] = useState(null)
  const [isStatus, setStatus] = useState(200)
  const [page, setPage] = useState(1)
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState(null)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [search, setSearch] = useState(null)

  const { t } = useTranslation('groupList')

  const { loading, totalResults, methodGet, methodDelete } = useFetch()

  const handleClose = () => {
    setShowModalDelete(false)
    setSelectedGroupToDelete(null)
  }
  const handleShow = () => setShowModalDelete(true)
  let navigate = useNavigate()

  const getGroupLists = async () => {
    let payload = {}
    if (search !== '' && search !== null) {
      payload = {
        search: search,
      }
    }
    const response = await methodGet(getSliderGroup(), payload)
    if (response) {
      setStatus(response.status)
      response.data && setGroupLists(response.data)
    } else {
      setStatus(500)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      getGroupLists()
    }, 500)
    return () => clearTimeout(timeout)
  }, [search])

  const openModalDelete = (group) => {
    handleShow()
    setSelectedGroupToDelete(group)
  }

  const goToEdit = (id) => {
    navigate(`/administrator/slider-group-details/${id}`)
  }

  const deleteGroup = async (groupToDelete) => {
    const response = await methodDelete(deleteGroupSlidersById(groupToDelete.id))
    if (response) {
      showToast('success', t('deletedSuccessful'))
      getGroupLists()
      handleClose()
    }
  }

  const renderTable = () => (
    <>
      {grouplists?.results?.length ? (
        <Table
          header={t('headerTableSliderGroup', { returnObjects: true })}
          page={page}
          changePage={setPage}
          next={grouplists ? (grouplists.links.next ? true : false) : false}
          previous={grouplists ? (grouplists.links.previous ? true : false) : false}
        >
          {grouplists?.results.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.slider_elements.length}</td>
              <td>{item.clients.length}</td>
              <td className="d-flex justify-content-center actions-row-buttons">
                <span className="action-btn-edit " onClick={() => goToEdit(item.id)}>
                  <GoPencil className="icon icon-edit " />
                </span>

                <span className="action-btn-delete " onClick={() => openModalDelete(item)}>
                  <RiDeleteBinLine className="icon icon-delete" />
                </span>
              </td>
            </tr>
          ))}
        </Table>
      ) : null}
    </>
  )

  const render = () => {
    if (loading) {
      return <Loading />
    } else {
      return renderTable()
    }
  }

  return (
    <Container className=" mt-3 pt-2 d-flex justify-content-center flex-column">
      <h2>{t('sliderGroup')}</h2>
      <Row className="my-2 w-100 d-flex justify-content-between">
        <Col lg={5} md={8} sm={8} xs={12}>
          <InputCustom
            placeholder={t('searchGroup')}
            className="border border-gray "
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col lg={5} md={4} xs={12} className="text-md-end">
          <Link to="/administrator/sliders-groups" >
            <button className="online-cprimary mt-0 me-2 btn btn-outline-sucess ">{t('assignClients')}</button>
          </Link>
          <Link to="/administrator/slider-group-details/new">
            <button className="online-cprimary mt-0 btn btn-outline-sucess ">{t('createNewGroup')}</button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          {isStatus === 404 && (
            <div className="box-message-plug">
              <p>{t('errorDataNotFound')}</p>
            </div>
          )}
          {isStatus === 500 && (
            <div className="box-message-plug">
              <p>{t('serverError')}</p>
            </div>
          )}
          {totalResults === 0 && !loading && (
            <Col md={12} className="box-message-plug">
              <p className="d-flex justify-content-center m-3">{t('noResults')}</p>
            </Col>
          )}
          {render()}
        </Col>
      </Row>
      <Modal show={showModalDelete} onHide={handleClose} centered>
        <Modal.Body>
          {t('delete', { ns: 'common' })} "{`${selectedGroupToDelete?.name}`}" ?
        </Modal.Body>
        <Modal.Footer>
          <CustomButton onClick={() => deleteGroup(selectedGroupToDelete)} className=" mt-0 btn btn-outline-sucess ">
            {t('delete', { ns: 'common' })}
          </CustomButton>
          <CustomButton onClick={handleClose} className=" online-cprimary mt-0 btn btn-outline-sucess  ">
            {t('cancel')}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default GroupSliders

import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";
import CustomButton from "./CustomButton";
import "../../assets/css/styles.scss";
import { useNavigate } from "react-router-dom";

const ComboButtons = ({
  cancelRoute,
  secondaryText,
  primaryText,
  handleClick,
}) => {
  let navigate = useNavigate();

  return (
    <Row className="row-buttons-actions">
      <CustomButton
        className=" online-cprimary mt-0 btn btn-outline-sucess  "
        onClick={() => navigate(cancelRoute)}
      >
        {secondaryText}
      </CustomButton>

      <CustomButton
        onClick={handleClick}
        className={" mt-0 btn btn-outline-sucess "}
      >
        {primaryText}
      </CustomButton>
    </Row>
  );
};

ComboButtons.propTypes = {
  handleClick: PropTypes.func,
  secondaryText: PropTypes.string,
  primaryText: PropTypes.string,
  cancelRoute: PropTypes.string,
};

export default ComboButtons;

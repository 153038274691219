import React, { useState } from 'react'
import { HiOutlineBell } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import ButtonGoBack from '../../../components/buttons/GoBackButton'
import SelectCustom from '../../../components/select'
import InputCustom from '../../../components/input'
import { CustomButton } from '../../../components/buttons'

import { exportKidsEdyeApi, exportLifeAndHealthEdyeApi } from '../../../api/pathsForHook/administrator'
import { showToast } from '../../../helpers'

import useFetch from '../../../hooks/useFetch'

const ExportEdye = () => {
  const [enabled, setEnabled] = useState(true)
  const { methodPost } = useFetch()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const options = [
    { id: 1, name: 'Infantil' },
    { id: 2, name: 'Vida y salud' },
  ]

  const { t } = useTranslation('exports', 'forms', 'common')

  const onSubmit = async (data) => {
    setEnabled(false)
    let url = ''
    if (data?.type === '1') url = exportKidsEdyeApi()
    else if (data?.type === '2') url = exportLifeAndHealthEdyeApi()
    if (url) {
      const rs = await methodPost(url, data)
      if (rs?.status === 200) {
        showToast('success', rs.data?.msg, 5000)
      } else {
        showToast('error', t('error', { ns: 'common' }))
      }
    }
    setEnabled(true)
  }

  return (
    <Container className="mt-3 pt-2 d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <h2>{t('export.edye')}</h2>
      <Row>
        <Col>
          <Alert variant="info d-flex flex-direction-row">
            <HiOutlineBell fontSize={40} />
            <div className="ms-3">
              Ingrese un correo electrónico y seleccione lo que desea exportar. <br />
              Cuando se termine de generar el excel con los registros correspondientes a EDYE te enviaremos un correo
              electrónico con un enlace para que puedas descargar.
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <Controller
            name="type"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t('forms.requiredField', { ns: 'forms' }),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                options={options}
                label={t('forms.recordsToExport', { ns: 'forms' })}
                placeholder={t('forms.selectType', { ns: 'forms' })}
                error={errors.type?.message}
                value={value}
                handleSelect={onChange}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t('forms.requiredField', { ns: 'forms' }),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('forms.invalidEmail', { ns: 'forms' }),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <InputCustom
                placeholder={t('forms.email', { ns: 'forms' })}
                label={t('forms.email', { ns: 'forms' })}
                type="email"
                value={value}
                onChange={onChange}
                error={errors.email?.message}
              />
            )}
          />
        </Col>
      </Row>
      <div className="d-flex flex-row-reverse pt-4">
        <CustomButton onClick={handleSubmit(onSubmit)} disabled={!enabled}>
          {t('export.export', { ns: 'exports' })}
        </CustomButton>
      </div>
    </Container>
  )
}

export default ExportEdye

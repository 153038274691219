import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

export const NoInfo = () => {
  const { t } = useTranslation('homeAdmin')
  return (
    <Row classname="mt-5">
      <Col lg={12} className='d-flex justify-content-center'>
        <h3 className="mt-5">{t('noData')}</h3>
      </Col>
    </Row>
  )
};

export default NoInfo;


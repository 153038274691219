import React from 'react';
import { useNavigate } from "react-router-dom";
//Icon
import { GoChevronLeft } from "react-icons/go";
//Component
import CustomButton from './CustomButton';
// Style
import '../../assets/css/buttons.scss'

const ButtonGoBack = () => {
    const navigate = useNavigate();

    return (
        <>
          <CustomButton
            className="button-go-back"
            onClick={() => navigate(-1)}
            >
                <GoChevronLeft/>
            </CustomButton> 
        </>
    );

}
export default ButtonGoBack;

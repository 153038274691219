import React from "react";

import { Card } from "react-bootstrap";
import "../../assets/css/styles.scss";

const CustomCard = (props) => {
  return (
    <Card className="card-container" {...props}>
      <Card.Body>
        {props.img ? (
          <div className="card-image">
            <img src={props.img} height={45} alt="Logo efilm" />
          </div>
        ) : null}
        <Card.Title>{props.title}</Card.Title>
        {props.children}
      </Card.Body>
    </Card>
  );
};

export default CustomCard;

import axios from "axios";
import { API_EFILM } from "../constants";
import { setValueStorage } from "../helpers";

const headers = {
  "Content-Type": "application/json",
};

const api = {
  handleApi() {
    const client = axios.create({
      baseURL: API_EFILM,
      headers: headers,
    });
    client.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("tokenAuth");
        const lan = localStorage.getItem("selectLanguage");
        if (token) {
          config.headers.Authorization = `JWT ${token}`;
        }
        config.headers["Accept-Language"] = lan;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          const refreshAuthToken = localStorage.getItem("refreshAuth");
          if (!refreshAuthToken) return Promise.reject(error);
          const payload = { refreshAuth: refreshAuthToken };
          try {
            const response = await axios.post(
              API_EFILM + "auth/refresh/",
              payload
            );
            if (response.status === 200) {
              setValueStorage(
                "tokenAuth",
                JSON.stringify(response.data.tokenAuth)
              );
              setValueStorage(
                "refreshAuth",
                JSON.stringify(response.data.refreshAuth)
              );
              return client(originalRequest);
            }
          } catch (error) {
            if (
              originalRequest.url === "/auth/" ||
              error.message === "Request failed with status code 401" ||
              error.response.data.detail === "Token is invalid or expired"
            ) {
              localStorage.removeItem("userInfo");
              localStorage.removeItem("refreshAuth");
              localStorage.removeItem("tokenAuth");
              if (
                error.message === "Request failed with status code 401" ||
                error.response.data.detail === "Token is invalid or expired"
              ) {
                window.location = "/";
              }
            } else {
              return client(originalRequest);
            }
          }
        }
        return Promise.reject(error);
      }
    );
    return client;
  },
};
export default api.handleApi();

import api from "./Api";

export async function methodGet(url, query) {
  try {
    const { status, data } = await api.get(url, { params: query });
    if (status === 200) {
      const response = { status, data };
      return response;
    } else {
      alert("GET: algo ha ido mal " + url + status);
    }
  } catch (error) {
    const response = { status: error.response };
    return response;
  }
}

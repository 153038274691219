import PropTypes from "prop-types";
import React from "react";

import { Button } from "react-bootstrap";

const CustomButton = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

CustomButton.defaultProps = {
  variant: "outline-success",
};

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CustomButton;
